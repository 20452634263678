import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { TitleService } from '../../../services/title.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { UserMultiplierService } from '../../../services/user-multiplier.service'

@Component({
  selector: 'app-user-multiplier-patients',
  templateUrl: './user-multiplier-patients.component.html',
})
export class UserMultiplierPatientsComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true

  public patients: PatientModel[] = []
  public documentNames: string[] = []
  public globalFilterValue = ''

  constructor(
    public userMultiplierService: UserMultiplierService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Patienten`)

    this.loadData()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userMultiplierService
      .loadPatients()
      .subscribe((data: PatientModel[]) => {
        this.patients = data

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
