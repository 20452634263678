<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <i class="tio icon-large">calendar_note</i>

  <h2>
    <i class="tio">calendar_note</i>
    Meine Termine
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>Nächste Termine</span>

    <div class="table-search-container">
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height">
      <span
        class="content-no-message with-padding"
        *ngIf="data.next_appointments.length === 0"
      >
        Keine Termine vorhanden
      </span>

      <p-table
        #dt
        [globalFilterFields]="[
          'caregiver.first_name',
          'caregiver.last_name',
          'caregiver.full_name'
        ]"
        [value]="data.next_appointments"
        [rowHover]="true"
        responsiveLayout="scroll"
        *ngIf="data.next_appointments.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="caregiver.last_name">
              Betreuungskraft
              <p-sortIcon field="caregiver.last_name"></p-sortIcon>
            </th>
            <th pSortableColumn="real_from">
              Datum <p-sortIcon field="real_from"></p-sortIcon>
            </th>
            <th>Uhrzeit</th>
            <th pSortableColumn="real_hours">
              Stunden <p-sortIcon field="real_hours"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-appointment>
          <tr>
            <td>{{ appointment.caregiver.last_first_name }}</td>
            <td>
              {{ appointment.real_from | date: "dd.MM.y" }} ({{
                appointment.weekday[0]
              }}{{ appointment.weekday[1] }})
              <i
                *ngIf="appointment.doctor_appointment"
                style="margin: 0 0 0 10px"
                class="p-tag p-tag-rounded p-tag-APPLICANT"
                >Arzttermin</i
              >

              <i
                *ngIf="appointment.shopping_appointment"
                style="margin: 0 0 0 10px"
                class="p-tag p-tag-rounded p-tag-APPLICANT"
                >Einkaufsfahrt</i
              >
            </td>
            <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
            <td>{{ appointment.real_hours_as_float }} Std</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>Letzte Termine</span>

    <div class="table-search-container">
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height">
      <span
        class="content-no-message with-padding"
        *ngIf="data.last_appointments.length === 0"
      >
        Keine Termine vorhanden
      </span>

      <p-table
        #dt2
        [globalFilterFields]="[
          'caregiver.first_name',
          'caregiver.last_name',
          'caregiver.full_name'
        ]"
        [paginator]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{totalRecords} Termine"
        [rows]="50"
        [value]="data.last_appointments"
        [rowHover]="true"
        scrollHeight="700px"
        [scrollable]="true"
        responsiveLayout="scroll"
        *ngIf="data.last_appointments.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="caregiver.last_name">
              Betreuungskraft
              <p-sortIcon field="caregiver.last_name"></p-sortIcon>
            </th>
            <th pSortableColumn="real_from">
              Datum <p-sortIcon field="real_from"></p-sortIcon>
            </th>
            <th>Uhrzeit</th>
            <th pSortableColumn="real_hours">
              Stunden <p-sortIcon field="real_hours"></p-sortIcon>
            </th>
            <th style="max-width: 50px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-appointment>
          <tr>
            <td>{{ appointment.caregiver.last_first_name }}</td>
            <td>
              {{ appointment.real_from | date: "dd.MM.y" }} ({{
                appointment.weekday[0]
              }}{{ appointment.weekday[1] }})
              <i
                *ngIf="appointment.doctor_appointment"
                style="margin: 0 0 0 10px"
                class="p-tag p-tag-rounded p-tag-APPLICANT"
                >Arzttermin</i
              >

              <i
                *ngIf="appointment.shopping_appointment"
                style="margin: 0 0 0 10px"
                class="p-tag p-tag-rounded p-tag-APPLICANT"
                >Einkaufsfahrt</i
              >
            </td>
            <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
            <td>{{ appointment.real_hours_as_float }} Std</td>
            <td class="table-action" style="max-width: 50px">
              <i
                class="pi pi-star show-always"
                pTooltip="Feedback geben"
                tooltipPosition="left"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
