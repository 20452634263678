<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span class="with-normal-checkbox">
      <p-checkbox
        label="Alle anzeigen"
        [binary]="true"
        (onChange)="loadAppointments(false)"
        [(ngModel)]="showAll"
        name="show_all"
      ></p-checkbox>

      <i
        (click)="goToPreviousMonth()"
        style="padding: 10px 15px; margin: 0"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        [disabled]="showAll"
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadAppointments(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        [disabled]="showAll"
        inputId="year"
        name="year"
        (onChange)="loadAppointments(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: ' 0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="padding: 10px 15px"
      ></i>
    </span>

    <div class="content-header-buttons">
      <!--      <p-dropdown-->
      <!--        inputId="month"-->
      <!--        name="month"-->
      <!--        placeholder="Monat"-->
      <!--        [showClear]="true"-->
      <!--        scrollHeight="500px"-->
      <!--        (onChange)="loadAppointments(false)"-->
      <!--        [options]="monthOptions"-->
      <!--        [(ngModel)]="month"-->
      <!--        [style]="{ width: '150px', zIndex: '10', margin: '0 0 0 -1px' }"-->
      <!--      ></p-dropdown>-->

      <!--      <p-dropdown-->
      <!--        inputId="year"-->
      <!--        name="year"-->
      <!--        placeholder="Jahr"-->
      <!--        [showClear]="true"-->
      <!--        (onChange)="loadAppointments(false)"-->
      <!--        [options]="yearOptions"-->
      <!--        [(ngModel)]="year"-->
      <!--        [style]="{ width: '120px', margin: '0 0 0 -1px' }"-->
      <!--      ></p-dropdown>-->

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <div class="content-body auto-height" *ngIf="appointments.length === 0">
    <span class="content-no-message">Keine Termine vorhanden</span>
  </div>
  <div
    class="content-body content-body-full-height-with-double-tabs without-padding"
    *ngIf="appointments.length > 0"
  >
    <p-table
      #dt
      [value]="appointments"
      [rowHover]="true"
      [globalFilterFields]="[
        'id',
        'caregiver.first_name',
        'caregiver.last_name',
        'persplan_function_complete',
        'caregiver.full_name'
      ]"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Termine"
      [rows]="50"
      sortMode="multiple"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 80px" pSortableColumn="has_storno_options">
            <i class="pi pi-exclamation-triangle" pTooltip="Stornos"></i>
            <p-sortIcon field="has_storno_options"></p-sortIcon>
          </th>
          <th pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th>Uhrzeit</th>
          <th pSortableColumn="real_hours">
            Stunden <p-sortIcon field="real_hours"></p-sortIcon>
          </th>
          <th pSortableColumn="costs">
            Kosten <p-sortIcon field="costs"></p-sortIcon>
          </th>
          <th pSortableColumn="persplan_function_complete">
            Budget <p-sortIcon field="persplan_function_complete"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr
          [class.background-hightlight-orange]="appointment.has_storno_options"
        >
          <td style="max-width: 80px">
            <i
              class="pi pi-exclamation-triangle color-alert"
              [pTooltip]="appointment.storno_details"
              *ngIf="appointment.has_storno_options"
            ></i>
          </td>
          <td style="min-width: 200px">
            <a routerLink="/caregivers/{{ appointment.caregiver_id }}">
              {{ appointment.caregiver.last_first_name }}
            </a>
          </td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday[0]
            }}{{ appointment.weekday[1] }})
            <i
              *ngIf="appointment.doctor_appointment"
              style="margin: 0 0 0 10px"
              class="p-tag p-tag-rounded p-tag-APPLICANT"
              >Arzttermin</i
            >

            <i
              *ngIf="appointment.shopping_appointment"
              style="margin: 0 0 0 10px"
              class="p-tag p-tag-rounded p-tag-APPLICANT"
              >Einkaufsfahrt</i
            >
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
          <td>{{ appointment.real_hours_as_float }} Std</td>
          <td>
            <div style="display: flex; gap: 5px; align-items: center">
              {{ appointment.costs_formatted }}
              <i
                style="font-size: 13px"
                *ngIf="appointment.current_price"
                class="pi pi-info-circle color-gray"
                [pTooltip]="appointment.current_price"
              ></i>
            </div>
          </td>
          <td>{{ appointment.persplan_function_complete }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
