<app-loader *ngIf="isLoggedIn === null" [loading]="true"></app-loader>

<div class="header-banner" *ngIf="isLoggedIn === true && isLocal">
  LOKALES SYSTEM
</div>

<div class="header-banner" *ngIf="isLoggedIn === true && isStaging">
  TEST SYSTEM
</div>

<div class="header-banner demo-banner" *ngIf="isLoggedIn === true && isDemo">
  DEMO SYSTEM
</div>

<app-header *ngIf="isLoggedIn === true"></app-header>
<app-nav *ngIf="isLoggedIn === true"></app-nav>

<!--<div *ngIf="user?.is_customer" class="subheader-banner">-->
<!--  <div>-->
<!--    <i class="tio">hearth</i>-->
<!--    <i class="tio">hearth</i>-->
<!--    <i class="tio">hearth</i>-->
<!--    <i class="tio">hearth</i>-->
<!--    <i class="tio">hearth</i>-->
<!--  </div>-->

<!--  Teilen Sie uns Ihr Feedback mit!-->
<!--</div>-->

<section class="content-container" [class.content-login]="isLoggedIn === false">
  <router-outlet></router-outlet>
</section>
