<app-loader [loading]="loading"></app-loader>

<div class="content has-min-width" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i
        style="padding: 10px 15px; margin: 0 0 0 -20px"
        (click)="goToPreviousYear()"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadAnalyses(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
      ></p-dropdown>

      <i
        style="padding: 10px 15px"
        (click)="goToNextYear()"
        class="pi pi-chevron-right"
      ></i>

      <span style="margin: 0 20px"
        >Beginn: {{ employed?.employed_since_date | date: "dd.MM.y" }}</span
      >
      <span
        *ngIf="caregiver.current_employment?.employed_until_date"
        style="margin: 0 20px"
        >Bis:
        {{
          caregiver.current_employment?.employed_until_date | date: "dd.MM.y"
        }}</span
      >
      <span style="margin: 0 20px"
        >Urlaub: {{ caregiver.vacation_days }} Tage</span
      >
      <span style="margin: 0 20px"
        >Stellenumfang: {{ caregiver.current_job_scope?.job_scope }} Std</span
      >
      <span style="margin: 0 20px"
        >AU Tage {{ year }}: {{ illQuota.days }}
        {{ illQuota.days === 1 ? "Tag" : "Tage" }}
        <i
          class="pi pi-info-circle"
          style="font-size: 12px"
          pTooltip="Nur bis zum aktuellen Monat"
        ></i
      ></span>
      <span style="margin: 0 20px"
        >AU Quote {{ year }}: {{ illQuota.percentage }} %</span
      >
    </span>

    <a
      [href]="caregiverAnalysesService.exportUrl(caregiverId, year)"
      target="_blank"
    >
      <button
        pButton
        label="Export"
        type="button"
        class="p-button-outlined"
        icon="pi pi-download"
        iconPos="left"
      ></button>
    </a>
  </header>
  <div
    class="content-body without-padding"
    [class.content-loading]="contentLoading"
  >
    <div class="time-month">
      <div class="time-category"></div>

      <!--      TODO: loop -->
      <div class="time-months">
        <div
          [class.hover-highlight]="
            currentHover === 0 || currentHoverForHeader === 0
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(0)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[0].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[0].value }} Tage</i>
          </span>
          <strong>Jan</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 1 || currentHoverForHeader === 1
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(1)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[1].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[1].value }} Tage</i>
          </span>
          <strong>Feb</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 2 || currentHoverForHeader === 2
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(2)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[2].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[2].value }} Tage</i>
          </span>
          <strong>Mrz</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 3 || currentHoverForHeader === 3
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(3)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[3].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[3].value }} Tage</i>
          </span>
          <strong>Apr</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 4 || currentHoverForHeader === 4
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(4)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[4].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[4].value }} Tage</i>
          </span>
          <strong>Mai</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 5 || currentHoverForHeader === 5
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(5)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[5].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[5].value }} Tage</i>
          </span>
          <strong>Jun</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 6 || currentHoverForHeader === 6
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(6)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[6].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[6].value }} Tage</i>
          </span>
          <strong>Jul</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 7 || currentHoverForHeader === 7
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(7)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[7].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[7].value }} Tage</i>
          </span>
          <strong>Aug</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 8 || currentHoverForHeader === 8
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(8)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[8].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[8].value }} Tage</i>
          </span>
          <strong>Sep</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 9 || currentHoverForHeader === 9
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(9)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[9].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[9].value }} Tage</i>
          </span>
          <strong>Okt</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 10 || currentHoverForHeader === 10
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(10)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[10].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[10].value }} Tage</i>
          </span>
          <strong>Nov</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 11 || currentHoverForHeader === 11
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(11)"
        >
          <span>
            <i pTooltip="Stellenumfang">{{ jobScopes[11].value }} Std</i>
            <i pTooltip="Arbeitstage pro Woche">{{ jobDays[11].value }} Tage</i>
          </span>
          <strong>Dez</strong>
        </div>
      </div>
    </div>

    <div class="time-lists">
      <div
        class="time-list"
        [class.background-hightlight-yellow]="timeKey === 'WORKING_TIME_SUM'"
        [class.divider]="
          timeKey === 'ACCUMULATED' ||
          timeKey === 'DRIVE_TIME_DIFFERENCE' ||
          timeKey === 'HW_BE'
        "
        [class.hidden]="times[timeKey].hidden"
        *ngFor="let timeKey of timeKeys"
      >
        <div class="time-category">
          {{ times[timeKey].name }}
          <i
            class="pi pi-info-circle"
            *ngIf="times[timeKey].tooltip"
            [pTooltip]="times[timeKey].tooltip"
          ></i>
        </div>
        <div class="times-container">
          <div
            class="times"
            (mouseleave)="setHoverForHeader()"
            (mouseenter)="setHoverForHeader(i)"
            [class.hover-highlight]="currentHover === i"
            [class.highlight]="month.highlight || month.from_system"
            *ngFor="let month of times[timeKey].months; let i = index"
          >
            <i
              class="pi pi-info-circle color-gray"
              style="margin-right: auto; font-size: 13px"
              *ngIf="month.tooltip"
              [escape]="false"
              tooltipStyleClass="tooltip-wider-left"
              tooltipPosition="left"
              [pTooltip]="month.tooltip"
            ></i>

            <div>
              <!--              <i-->
              <!--                *ngIf="timeKey === 'DRIVE_TIME_MANUALLY'"-->
              <!--                class="pi pi-pencil color-gray"-->
              <!--                pTooltip="Bearbeiten"-->
              <!--                tooltipPosition="left"-->
              <!--                (click)="openChangeDriveTimeManuallyDialog(month)"-->
              <!--              ></i>-->

              <i
                *ngIf="timeKey === 'SOLL_PERSPLAN'"
                class="pi pi-pencil color-gray"
                pTooltip="Bearbeiten"
                tooltipPosition="left"
                (click)="
                  openChangeCaregiverPersplanTimeCorrectionDialog(month, i)
                "
              ></i>

              <i
                *ngIf="timeKey === 'CORRECTION'"
                class="pi pi-pencil color-gray"
                pTooltip="Bearbeiten"
                tooltipPosition="left"
                (click)="openChangeCaregiverTimeCorrectionDialog(month)"
              ></i>

              <i
                class="pi pi-comment color-blue"
                *ngIf="month.change_reason"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider"
                [pTooltip]="month.change_reason"
              ></i>
            </div>

            <span class="times-values">
              <span>{{ month.value }} {{ month.suffix }}</span>
              <span
                class="value-from-persplan"
                pTooltip="Laut Persplan"
                *ngIf="
                  month.value_from_persplan &&
                  month.value_from_persplan != '0,00' &&
                  month.value_from_persplan != month.value
                "
                >{{ month.value_from_persplan }} {{ month.suffix }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
