<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Einsätze die umgeplant werden müssen</span>

    <div class="content-header-buttons">
      <p-checkbox
        (onChange)="loadAppointments(false)"
        label="Nur heute und morgen"
        [binary]="true"
        [(ngModel)]="filters.only_today"
        name="only_today"
      ></p-checkbox>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="data.length === 0">
    <span class="content-no-message">Keine Einsätze vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="data.length > 0"
    [class.content-loading]="contentLoading"
  >
    <p-table
      #dt
      [value]="data"
      [rowHover]="true"
      [globalFilterFields]="[
        'id',
        'caregiver.first_name',
        'caregiver.last_name',
        'caregiver.full_name',
        'patient.last_name',
        'patient.first_name',
        'patient.full_name',
        'patient.city',
        'change_required_reason'
      ]"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [rows]="50"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th style="max-width: 30px"></th>
          <th pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="patient.city">
            Ort <p-sortIcon field="patient.city"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from_h">
            Uhrzeit <p-sortIcon field="real_from_h"></p-sortIcon>
          </th>
          <th pSortableColumn="change_required_reason">
            Grund <p-sortIcon field="change_required_reason"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              placeholder="Suche Name"
              [showMenu]="false"
              type="text"
              field="caregiver.full_name"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th style="max-width: 30px"></th>
          <th>
            <p-columnFilter
              placeholder="Suche Name"
              [showMenu]="false"
              type="text"
              field="patient.full_name"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              placeholder="Suche Ort oder Bezirke"
              [showMenu]="false"
              type="text"
              field="patient.full_address_complete"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="date"
              field="real_from_formatted"
              [showMenu]="true"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-calendar
                  placeholder="Datum"
                  #fd
                  [ngModel]="value"
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                  (onBlur)="filter(fd.value)"
                  (onSelect)="filter(fd.value)"
                ></p-calendar>
              </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr
          [class.background-hightlight-yellow]="
            appointment.doctor_appointment || appointment.shopping_appointment
          "
          [class.background-hightlight-alert]="appointment.is_past"
          [class.background-hightlight-main2]="
            currentSelectedCustomerId === appointment.patient.customer_id
          "
        >
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/caregivers/{{ appointment.caregiver.id }}/planning"
            >
              {{ appointment.caregiver.full_name }}
            </a>
          </td>
          <td style="max-width: 30px">
            <i
              *ngIf="
                appointment.patient.customer.lng_location &&
                appointment.patient.customer.lat_location
              "
              (click)="
                openNearPatients(appointment.patient.customer_id, $event)
              "
              pTooltip="Patienten in der Nähe"
              class="pi clickable-pi-icon"
              [class.pi-map-marker]="
                !nearPatientsLoading[appointment.patient.customer_id]
              "
              [class.pi-spin]="
                nearPatientsLoading[appointment.patient.customer_id]
              "
              [class.pi-spinner]="
                nearPatientsLoading[appointment.patient.customer_id]
              "
            ></i>
          </td>
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/patients/{{ appointment.patient.id }}/planning"
            >
              {{ appointment.patient.last_name }},
              {{ appointment.patient.first_name }}
            </a>
          </td>
          <td>{{ appointment.patient.city }}</td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday
            }})
            <i
              *ngIf="appointment.doctor_appointment"
              style="margin: 0 0 0 10px"
              class="p-tag p-tag-rounded p-tag-APPLICANT"
              >Arzttermin</i
            >

            <i
              *ngIf="appointment.shopping_appointment"
              style="margin: 0 0 0 10px"
              class="p-tag p-tag-rounded p-tag-APPLICANT"
              >Einkaufsfahrt</i
            >
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
          <td>{{ appointment.change_required_reason || "-" }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-overlayPanel
  #opNearPatients
  styleClass="with-border"
  [showCloseIcon]="true"
  [style]="{
    maxWidth: '800px',
    width: 'max-content',
    maxHeight: '420px',
    overflowY: 'auto'
  }"
>
  <ng-template pTemplate>
    <div *ngIf="nearPatients.length === 0">Keine Patienten in der Nähe</div>
    <div style="display: flex; padding: 0 0 5px 0">
      <div class="p-inputgroup" style="width: 100px">
        <input
          [(ngModel)]="area"
          id="area"
          name="area"
          type="text"
          pInputText
          (blur)="openNearPatients(null, null, false)"
        />
        <span class="p-inputgroup-addon">KM</span>
      </div>
    </div>
    <p-table [value]="nearPatients" *ngIf="nearPatients.length > 0">
      <ng-template pTemplate="header">
        <tr>
          <th>Patient</th>
          <th>Adresse</th>
          <th style="width: 100px" pSortableColumn="distance_sortable">
            Umkreis <p-sortIcon field="distance_sortable"></p-sortIcon>
          </th>
          <th pSortableColumn="next_appointment.from_SOLL">
            Nächster Einsatz
            <p-sortIcon field="next_appointment.from_SOLL"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <a target="_blank" routerLink="/patients/{{ item.patient_id }}">{{
              item.patient
            }}</a>
          </td>
          <td>
            {{ item.street }}
            <span class="table-small-info"><br />{{ item.city }}</span>
          </td>
          <td>{{ item.distance }} KM</td>

          <td *ngIf="!item.next_appointment">-</td>
          <td *ngIf="item.next_appointment">
            {{ item.next_appointment.from_SOLL | date: "dd.MM.y" }},
            {{ item.next_appointment.from_SOLL_h }} -
            {{ item.next_appointment.to_SOLL_h }} Uhr
            <span class="table-small-info"
              ><br />
              <a
                target="_blank"
                routerLink="/caregivers/{{
                  item.next_appointment.caregiver.id
                }}/overview"
                >{{ item.next_appointment.caregiver.first_name }}
                {{ item.next_appointment.caregiver.last_name }}</a
              >
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
