import { Component, OnInit } from '@angular/core'
import { MessageService } from 'primeng/api'
import {
  PhoneCallDataModel,
  PhoneCallEventModel,
  PhoneCallOpenModel,
} from '../../../models/phone-call/phone-call-event.model'
import { PhoneCallModel } from '../../../models/phone-call/phone-call.model'
import { PhoneCallService } from '../../../services/phone-call.service'
import { ToastService } from '../../../services/toast.service'
import { PhoneToastMessageModel } from '../../../models/phone-call/phone-toast-message.model'
import { AuthService } from '../../../services/auth.service'
import { HelperService } from '../../../services/helper.service'
import { AddCancelledDateDialogComponent } from '../../dialogs/add-cancelled-date-dialog/add-cancelled-date-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { TodoFormDialogComponent } from '../../dialogs/todo-form-dialog/todo-form-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { BroadcastChannel } from 'broadcast-channel'
import {SearchResultModel} from "../../../models/search/search-result.model";
import {SearchService} from "../../../services/search.service";

@Component({
  selector: 'app-phone-call',
  templateUrl: './phone-call.component.html',
})
export class PhoneCallComponent implements OnInit {
  private callingFinishedWithoutActiveMessages: any = []
  private callingMessages: any = []
  private incomingMessages: any = []
  private activeMessages: any = []
  private finishMessages: any = []

  public submittedDelete = false

  public attentionFrom = []
  public appointments: any = []
  public patients: SearchResultModel[] = []

  public hiddenMessages: number[] = []

  constructor(
    private messageService: MessageService,
    private phoneCallService: PhoneCallService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private searchService: SearchService,
    private authService: AuthService,
    private toastService: ToastService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadOpenPhoneCalls()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.attentionFrom = data['attention_from']
    })

    const user = this.authService.getUser()

    // @ts-ignore
    const channel = window.Echo.private(`phone.${user?.id}`)

    // Ankommender Anruf
    channel.listen('PhoneIncommed', (event: PhoneCallEventModel) => {
      this.addIncoming(event.data)
    })

    // Ein ausgehender Anruf wurde gestartet
    channel.listen('PhoneCallingCreated', (event: PhoneCallEventModel) => {
      this.addCalling(event.data)
    })

    // Ein ausgehender Anruf wurde beendet, ohne das er aktiv war
    channel.listen(
      'PhoneCallingFinishedWithoutActive',
      (event: PhoneCallEventModel) => {
        this.addCallingFinishedWithoutActive(event.data)
        this.closeCalling(event.data.phone_call)
      }
    )

    // Angenommener Anruf
    channel.listen('PhoneActivated', (event: PhoneCallEventModel) => {
      this.addActive(event.data)
    })

    // Beendeter Anruf
    channel.listen('PhoneFinished', (event: PhoneCallEventModel) => {
      this.addFinish(event.data.phone_call)
    })

    // Gespeicherter Anruf
    channel.listen('PhoneSaved', (event: PhoneCallEventModel) => {
      this.removeFromFinish(event.data.phone_call)
      this.removeFromFinishedWithoutActiveMessages(event.data.phone_call)
    })

    // Alle ankommenden Anrufe sollen geschlossen werden,
    // weil jemand den Anruf entgegengenommen hat
    channel.listen('PhoneIncommedClosed', (event: PhoneCallEventModel) => {
      this.closeIncoming(event.data.phone_call)
      this.closeCalling(event.data.phone_call)
    })
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  /**
   * Alle Telefonate für den Benutzer, die gerade aktiv sind
   * oder beendet wurden aber noch nicht gespeichert wurden.
   *
   * So gehen die Telefonate beim neu laden der Seite nicht verloren.
   */
  private loadOpenPhoneCalls(): void {
    this.phoneCallService.open().subscribe((data: PhoneCallOpenModel) => {
      if (data.active_call) {
        this.addActive(data.active_call)
      }

      for (const finishCall of data.finish_calls) {
        const message = {
          sticky: true,
          key: 'phone-finish',
          closable: false,
          data: {
            id: finishCall.phone_call.id,
            number:
              finishCall.phone_call.number ||
              finishCall.phone_call.original_number,
            found_callers: finishCall.found_callers,
            comment: '',
            appointment_transfer: 'NO',
            from_appointment: finishCall.phone_call.from_appointment,
            from_appointment_patient_id:
              finishCall.phone_call.from_appointment_patient_id,
            from_appointment_month:
              finishCall.phone_call.from_appointment_month,
            from_appointment_year: finishCall.phone_call.from_appointment_year,
            loading: false,
            history: {
              important: finishCall.phone_call.history
                ? finishCall.phone_call.history.important
                : false,
            },
            selected_caller: finishCall.found_callers[0]
              ? finishCall.found_callers[0]
              : null,
          } as PhoneToastMessageModel,
        }

        this.finishMessages.push(message)
        this.messageService.add(message)
      }
    })
  }

  private removeFromFinish(phoneCall: PhoneCallModel): void {
    this.finishMessages = this.finishMessages.filter((m: any) => {
      return m.data?.id !== phoneCall.id
    })

    this.messageService.clear('phone-finish')

    this.finishMessages.forEach((m: any) => {
      this.messageService.add(m)
    })
  }

  private removeFromFinishedWithoutActiveMessages(
    phoneCall: PhoneCallModel
  ): void {
    this.callingFinishedWithoutActiveMessages = this.callingFinishedWithoutActiveMessages.filter(
      (m: any) => {
        return m.data?.id !== phoneCall.id
      }
    )

    this.messageService.clear('phone-calling-finished-without-active')

    this.callingFinishedWithoutActiveMessages.forEach((m: any) => {
      this.messageService.add(m)
    })
  }

  private addFinish(phoneCall: PhoneCallModel): void {
    const message = this.activeMessages.find((m: any) => {
      return m.data.id === phoneCall.id
    })

    this.activeMessages = this.activeMessages.filter(
      (m: any) => m.data.id !== phoneCall.id
    )

    this.messageService.clear('phone-active')

    this.activeMessages.forEach((m: any) => {
      this.messageService.add(m)
    })

    // Falls das Telefonat aus dem Terminversand-Dialog erstellt wurde.
    if (phoneCall.from_appointment) {
      message.data.appointment_transfer = 'NEXT_MONTH'
    }

    this.finishMessages.push({ ...message, key: 'phone-finish' })
    this.messageService.add({ ...message, key: 'phone-finish' })
  }

  private addIncoming(data: PhoneCallDataModel): void {
    const message = {
      sticky: true,
      key: 'phone-incoming',
      closable: false,
      data: {
        id: data.phone_call.id,
        number: data.phone_call.number || data.phone_call.original_number,
        found_callers: data.found_callers,
      },
    }

    this.incomingMessages.push(message)
    this.messageService.add(message)
  }

  private addCalling(data: PhoneCallDataModel): void {
    const message = {
      sticky: true,
      key: 'phone-calling',
      closable: false,
      data: {
        id: data.phone_call.id,
        number: data.phone_call.number || data.phone_call.original_number,
      },
    }

    this.callingMessages.push(message)
    this.messageService.add(message)
  }

  private addCallingFinishedWithoutActive(data: PhoneCallDataModel): void {
    const message = {
      sticky: true,
      key: 'phone-calling-finished-without-active',
      closable: false,
      data: {
        comment: '',
        loading: false,
        not_reached: false,
        id: data.phone_call.id,
        number: data.phone_call.number || data.phone_call.original_number,
        found_callers: data.found_callers,
        selected_caller: data.found_callers[0] ? data.found_callers[0] : null,
      },
    }

    this.callingFinishedWithoutActiveMessages.push(message)
    this.messageService.add(message)
  }

  private addActive(data: PhoneCallDataModel): void {
    const message = {
      sticky: true,
      key: 'phone-active',
      closable: false,
      data: {
        id: data.phone_call.id,
        number: data.phone_call.number || data.phone_call.original_number,
        found_callers: data.found_callers,
        comment: '',
        appointment_transfer: 'NO',
        from_appointment: data.phone_call.from_appointment,
        from_appointment_patient_id:
          data.phone_call.from_appointment_patient_id,
        from_appointment_month: data.phone_call.from_appointment_month,
        from_appointment_year: data.phone_call.from_appointment_year,
        not_reached: false,
        loading: false,
        history: {
          important: false,
        },
        selected_caller: data.found_callers[0] ? data.found_callers[0] : null,
      } as PhoneToastMessageModel,
    }

    this.activeMessages.push(message)
    this.messageService.add(message)
  }

  public toggleMessageHidden(id: number): void {
    if (this.hiddenMessages.includes(id)) {
      this.hiddenMessages = this.hiddenMessages.filter(
        (entry: number) => entry !== id
      )
    } else {
      this.hiddenMessages.push(id)
    }
  }

  public deleteCallingFinishedWithoutActive(id: number): void {
    this.submittedDelete = true

    this.phoneCallService.delete(id).subscribe(
      () => {},
      () => {
        this.submittedDelete = true
        this.toastService.error(
          'Telefonat konnte nicht gelöscht werden',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }

  public openAddCancelledDateDialog(data: PhoneToastMessageModel): void {
    this.dialogService.open(AddCancelledDateDialogComponent, {
      header: 'Termine absagen',
      width: '640px',
      styleClass: 'dialog-container',
      data: {
        from_phone: true,
        customer_id: data.selected_caller.phone_caller_id,
        // ...this.customer,
        // patient_id: this.customer[this.selectedPatient]?.id,
      },
    })
  }

  public openTodoDialog(data: PhoneToastMessageModel): void {
    // const ref = this.dialogService.open(TodoFormDialogComponent, {
    //   header: 'Neues Todo',
    //   width: '520px',
    //   styleClass: 'dialog-container',
    //   data: {
    //     isPreSelected: true,
    //     selectedType: 'PATIENT',
    //     user_type_name: this.data.item.data.patient.full_name,
    //     user_type_id: this.data.item.data.patient.id,
    //     plansFeedback: true,
    //     plansFeedbackType: this.data.item.type,
    //     plansFeedbackId: this.data.item.type_id,
    //     comment,
    //   },
    // })
    //
    // ref.onClose.subscribe((hasCreated: boolean) => {
    //   if (hasCreated) {
    //     this.todoCreated = true
    //     this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
    //   }
    // })
  }

  /**
   * Setzt das aktive Telefonat manuell auf Finished.
   * Kann öfters vorkommen, wenn irgendwas mit der Telefonanlage ist.
   */
  public finishFromActive(data: PhoneToastMessageModel): void {
    this.phoneCallService.finishFromActive(data).subscribe(
      () => {
        this.eventbus.emit(GlobalEvent.PhoneCallListReload)
      },
      () => {
        this.toastService.error(
          'Telefonat konnte nicht beendet werden',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }

  /**
   * Sobald eine Terminvergabe im Telefonat ausgewählt wurde,
   * sollen die Termine für den ausgewählten Monat
   * (aktueller oder nächster) angezeigt werden.
   *
   * TODO: Kann weg, wird nicht wirklich benötigt
   */
  public getPersplanMonthForSelectPhone(data: PhoneToastMessageModel): void {
    this.appointments = []

    const isCustomer = data.selected_caller.phone_caller_type === 'customer'
    const isAppointmentTransfer = data.appointment_transfer !== 'NO'

    if (isCustomer && isAppointmentTransfer) {
      this.phoneCallService
        .getPersplanMonthForSelectPhone(
          data.selected_caller.phone_caller_id,
          data.appointment_transfer
        )
        .subscribe((response: any) => {
          this.appointments = response
        })
    }
  }

  /**
   * Speichert den Telefoneintrag.
   */
  public save(data: PhoneToastMessageModel): void {
    data.loading = true

    this.phoneCallService.save(data).subscribe(
      () => {
        this.eventbus.emit(GlobalEvent.PhoneCallListReload)
        this.toastService.success('Telefonat wurde erfolgreich gespeichert')

        const channel = new BroadcastChannel('appointment-sent-from-phone')
        channel.postMessage({
          data,
        })
      },
      () => {
        data.loading = false

        this.toastService.error(
          'Telefonat konnte nicht gespeichert werden',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }

  private closeIncoming(phoneCall: PhoneCallModel): void {
    this.incomingMessages = this.incomingMessages.filter(
      (m: any) => m.data.id !== phoneCall.id
    )

    this.messageService.clear('phone-incoming')

    this.incomingMessages.forEach((m: any) => {
      this.messageService.add(m)
    })
  }

  private closeCalling(phoneCall: PhoneCallModel): void {
    this.callingMessages = this.callingMessages.filter(
      (m: any) => m.data.id !== phoneCall.id
    )

    this.messageService.clear('phone-calling')

    this.callingMessages.forEach((m: any) => {
      this.messageService.add(m)
    })
  }
}
