import { Component, OnDestroy, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { DocumentModelSimple } from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { TitleService } from '../../../services/title.service'
import { UserCustomerService } from '../../../services/user-customer.service'
import { AddUserCustomerRequestDialogComponent } from '../../../components/dialogs/add-user-customer-request-dialog/add-user-customer-request-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-user-caregiver-overview',
  styleUrls: ['./user-customer-overview.component.scss'],
  templateUrl: './user-customer-overview.component.html',
})
export class UserCustomerOverviewComponent implements OnInit, OnDestroy {
  public loading = false

  public documents: DocumentModelSimple[] = []
  public documentNames: string[] = []

  constructor(
    private dialogService: DialogService,
    public userCustomerService: UserCustomerService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Übersicht`)

    this.loadDocuments()
  }

  ngOnDestroy(): void {}

  public openUserCustomerRequestDialog(request: any = null): void {
    this.dialogService.open(AddUserCustomerRequestDialogComponent, {
      header: request ? 'Anfrage bearbeiten' : 'Neue Anfrage',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!request,
        request,
      },
    })
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.userCustomerService.getDownloadDocumentLink(document.uuid))
  }

  public loadDocuments(withLoading: boolean = true): void {
    // if (withLoading) {
    //   this.loading = true
    // }
    //
    // this.userCustomerService
    //   .loadDocuments()
    //   .subscribe((data: DocumentModelSimple[]) => {
    //     this.documents = data
    //     this.documentNames = Object.keys(data)
    //
    //     this.loading = false
    //   })
  }
}
