<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar white-sidebar without-padding">
    <div class="dialog-sidebar-tabs">
      <span
        [class.active]="activeTab === 'CALENDAR'"
        (click)="changeTab('CALENDAR')"
        pTooltip="Kalender"
        ><i class="pi pi-calendar"></i
        >{{ activeTab === "CALENDAR" ? "Kalender" : "" }}</span
      >
      <span
        [class.active]="activeTab === 'DESIRED'"
        (click)="changeTab('DESIRED')"
        pTooltip="Wunschtermine"
        ><i class="pi pi-calendar-plus"></i
        >{{ activeTab === "DESIRED" ? "Wunschtermine" : "" }}</span
      >
      <span
        [class.active]="activeTab === 'PLANNINGS'"
        (click)="changeTab('PLANNINGS')"
        pTooltip="Einsätze"
        ><i class="pi pi-clock"></i
        >{{ activeTab === "PLANNINGS" ? "Einsätze" : "" }}</span
      >
      <span
        [class.active]="activeTab === 'HISTORY'"
        (click)="changeTab('HISTORY')"
        pTooltip="History"
        *ngIf="histories.length > 0"
        ><i class="pi pi-history"></i
        >{{ activeTab === "HISTORY" ? "History" : "" }}</span
      >
      <span
        [class.active]="activeTab === 'AREA'"
        pTooltip="Umgebung"
        (click)="changeTab('AREA')"
        ><i class="pi pi-map-marker"></i>
        {{ activeTab === "AREA" ? "Umgebung" : "" }}</span
      >
    </div>

    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'PLANNINGS'"
    >
      <div class="dialog-sidebar-accordion-container" style="padding: 5px">
        <div
          class="dialog-sidebar-accordion"
          *ngFor="let appointment of keys(lastAppointments.grouped)"
        >
          <div
            class="dialog-sidebar-accordion-header"
            (click)="accordions[appointment] = !accordions[appointment]"
          >
            {{ appointment }}
            <i
              class="pi"
              [class.pi-chevron-down]="!accordions[appointment]"
              [class.pi-chevron-up]="accordions[appointment]"
            ></i>
          </div>
          <div
            class="dialog-sidebar-accordion-content"
            *ngIf="accordions[appointment]"
          >
            <div
              class="dialog-sidebar-accordion-list not-clickable"
              *ngFor="let item of lastAppointments.grouped[appointment]"
            >
              <span>
                {{ item.weekday }} ({{ item.real_from | date: "dd.MM" }}) um
                {{ item.real_from_h }} - {{ item.real_to_h }} ({{
                  item.caregiver.first_last_name
                }})
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'AREA'"
    >
      <div *ngIf="nearPatients.length === 0">Keine Patienten in der Nähe</div>
      <div style="display: flex; padding: 5px 10px">
        <div class="p-inputgroup" style="width: 100px">
          <input
            [(ngModel)]="area"
            id="area"
            name="area"
            type="text"
            pInputText
            (blur)="openNearPatients()"
          />
          <span class="p-inputgroup-addon">KM</span>
        </div>
      </div>
      <p-table [value]="nearPatients" *ngIf="nearPatients.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>Patient</th>
            <th style="width: 100px" pSortableColumn="distance_sortable">
              Umkreis <p-sortIcon field="distance_sortable"></p-sortIcon>
            </th>
            <th pSortableColumn="next_appointment.from_SOLL">
              Nächster Einsatz
              <p-sortIcon field="next_appointment.from_SOLL"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <a target="_blank" routerLink="/patients/{{ item.patient_id }}">{{
                item.patient
              }}</a>
              <!--              <br />-->
              <!--              <span class="table-small-info"-->
              <!--                >{{ item.street }}<br />{{ item.city }}</span-->
              <!--              >-->
            </td>
            <td>{{ item.distance }} KM</td>
            <td *ngIf="!item.next_appointment">-</td>
            <td *ngIf="item.next_appointment">
              {{ item.next_appointment.from_SOLL | date: "dd.MM.y" }},
              {{ item.next_appointment.from_SOLL_h }} -
              {{ item.next_appointment.to_SOLL_h }} Uhr
              <span class="table-small-info"
                ><br />
                <a
                  target="_blank"
                  routerLink="/caregivers/{{
                    item.next_appointment.caregiver.id
                  }}/overview"
                  >{{ item.next_appointment.caregiver.first_name }}
                  {{ item.next_appointment.caregiver.last_name }}</a
                >
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'DESIRED'"
    >
      <div *ngIf="desiredDates.length === 0" style="margin: 30px 0">
        <span class="content-no-message">Keine Wunschtermine vorhanden</span>
      </div>

      <p-table [value]="desiredDates" *ngIf="desiredDates.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>Datum</th>
            <th>Bemerkung</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td *ngIf="item.no_time">
              {{ item.date_at | date: "dd.MM.y" }}<br /><span
                class="color-gray"
                >{{ item.date_at | date: "EEEE" }}</span
              >
            </td>
            <td *ngIf="!item.no_time">
              {{ item.time_from | date: "dd.MM.y, H:mm" }} -
              {{ item.time_to | date: "H:mm" }}<br /><span class="color-gray">{{
                item.date_at | date: "EEEE"
              }}</span>
            </td>
            <td>
              <i
                class="pi pi-info-circle"
                *ngIf="item.comment"
                [pTooltip]="item.comment"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'CALENDAR'"
    >
      <p-calendar [firstDayOfWeek]="1" [showWeek]="true" [inline]="true">
        <ng-template pTemplate="date" let-date>
          <!-- Der Monat fängt hier mit 0 an, deswegen addieren wir eins dazu -->
          <span
            [pTooltip]="
              (holidays[date.day + '.' + (date.month + 1) + '.' + date.year] ||
                '') +
              (desiredDatesCalendar[
                date.day + '.' + (date.month + 1) + '.' + date.year
              ] || '') +
              appointmentForDate(date)
            "
            [class.background-hightlight-yellow]="
              holidays[date.day + '.' + (date.month + 1) + '.' + date.year]
            "
            [class.background-hightlight-main2]="
              desiredDatesCalendar[
                date.day + '.' + (date.month + 1) + '.' + date.year
              ]
            "
            [class.background-hightlight-blue]="
              lastAppointments.appointments[
                date.day + '.' + (date.month + 1) + '.' + date.year
              ]
            "
            >{{ date.day }}</span
          >
        </ng-template>
      </p-calendar>

      <div
        class="dialog-sidebar-has-table"
        style="padding: 10px; border-top: 1px solid #dfe3de"
      >
        <span class="content-sub-headline">Leistungstage des Patienten</span>

        <div
          class=""
          style="margin: 0"
          [innerHTML]="data.patient.times.days_as_html_table"
        ></div>
        <small class="color-gray" *ngIf="data.patient.appointment_interval"
          >Intervall: {{ data.patient.appointment_interval }}</small
        >
        <small class="color-gray"
          ><br />Leistungsumfang:
          {{ data.patient.customer.service_scope || "-" }}</small
        >
      </div>

      <div
        class="dialog-sidebar-has-table"
        style="padding: 10px; border-top: 1px solid #dfe3de"
      >
        <span class="content-sub-headline"
          >Arbeitszeiten der Betreuungskraft</span
        >

        <small class="color-gray" *ngIf="!appointment.caregiver_id"
          >Bitte wählen Sie eine Betreuungskraft aus</small
        >

        <div
          class=""
          style="margin: 0"
          *ngIf="appointment.caregiver_id"
          [innerHTML]="getCaregiverTimeTable()"
        ></div>
      </div>
    </div>

    <ng-container *ngIf="activeTab === 'HISTORY'">
      <div
        class="sidebar-item"
        [class.clickable]="
          ['PATIENT_PLANNING_CHANGED', 'PATIENT_PLANNING_DELETED'].includes(
            history.type
          )
        "
        (click)="
          ['PATIENT_PLANNING_CHANGED', 'PATIENT_PLANNING_DELETED'].includes(
            history.type
          )
            ? openDiffView(history.id)
            : null
        "
        *ngFor="let history of histories"
      >
        <div class="sidebar-content">
          <strong class="sidebar-title">{{ history.manual_title }}</strong>
          <span class="sidebar-info"
            >{{ history.occurred_at_string }} von
            {{ history.created_from_name }}</span
          >
          <p class="sidebar-text">
            Einsatz: {{ history.content.real_from | date: "dd.MM.y" }},
            {{ history.content.real_from_h }} - {{ history.content.real_to_h }}
            <br />Für: {{ history.content.caregiver.first_last_name }}
          </p>
        </div>
        <i
          class="pi pi-eye"
          *ngIf="
            ['PATIENT_PLANNING_CHANGED', 'PATIENT_PLANNING_DELETED'].includes(
              history.type
            )
          "
        ></i>
      </div>
    </ng-container>
  </div>

  <div
    class="dialog-sidebar secondary-dialog-sidebar middle-dialog-sidebar without-padding"
  >
    <div
      *ngIf="
        appointment.with_interval && checkedIntervalAppointments.length > 0
      "
    >
      <span class="content-sub-headline" style="padding: 20px 20px 0 20px"
        >Serientermine</span
      >

      <p-table [value]="checkedIntervalAppointments" [rowHover]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>Termin</th>
            <th style="width: 40px"></th>
            <th>Datum</th>
            <th></th>
            <th style="width: 50px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr [class.background-hightlight-alert]="item.new_check_issue">
            <td>
              <p-checkbox
                (onChange)="appointmentSelected(item)"
                label="Erstellen"
                [name]="'create-' + rowIndex"
                [binary]="true"
                [disabled]="!item.is_selectable || checkingBudgets"
                [(ngModel)]="item.is_selected"
              ></p-checkbox>
            </td>
            <td>
              <i
                tooltipStyleClass="tooltip-wider"
                class="pi pi-exclamation-triangle color-orange"
                *ngIf="item.plausibility && item.is_selectable"
                [pTooltip]="item.plausibility"
              ></i>
              <i
                tooltipStyleClass="tooltip-wider"
                class="pi pi-exclamation-triangle color-alert"
                *ngIf="item.plausibility && !item.is_selectable"
                [pTooltip]="item.plausibility"
              ></i>
            </td>
            <td>{{ item.date }} ({{ item.day }})</td>
            <td>
              <button
                pButton
                label="Wunschtermin"
                (click)="toggleWish(item)"
                [class.p-button-outlined]="!item.is_wish"
                class="p-button-small"
                type="submit"
              ></button>
            </td>
            <td>
              <i
                *ngIf="item.tooltip_patients"
                style="font-size: 14px"
                class="pi pi-info-circle"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                [pTooltip]="item.tooltip_patients"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div
      *ngIf="!appointment.with_interval || isEditMode"
      class="dialog-sidebar-has-table"
      style="padding: 20px"
    >
      <div>
        <span class="content-sub-headline">Einsätze der Betreuungskraft</span>

        <small
          class="color-gray"
          *ngIf="!appointment.caregiver_id || !appointment.date"
          >wählen Sie eine Betreuungskraft und ein Datum</small
        >

        <small
          class="color-gray"
          *ngIf="
            appointment.caregiver_id &&
            appointment.date &&
            appointmentsForCaregiver.length === 0
          "
          >Keine Einsätze für diesen Tag</small
        >
      </div>
      <div style="margin: 0" *ngIf="appointment.caregiver_id">
        <small
          style="display: flex; flex-direction: column; margin: 0"
          *ngFor="let appointment of appointmentsForCaregiver; let i = index"
        >
          <span *ngIf="i === 0">Von Zuhause</span>
          <small class="drive-time-info-in-list">
            <i style="font-size: 12px" class="pi pi-car color-gray"></i>
            {{ appointment.from_patient_duration_seconds_rounded / 60 }} min
          </small>
          <span
            [class.current-patient-highlight]="
              appointment.patient.id == data.patient.id
            "
          >
            {{ appointment.patient.first_name }}
            {{ appointment.patient.last_name }} {{ appointment.real_from_h }} -
            {{ appointment.real_to_h }}
          </span>
        </small>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="caregiver">Betreuungskraft *</label>
        <p-dropdown
          scrollHeight="300px"
          inputId="caregiver"
          name="caregiver"
          placeholder="Bitte auswählen"
          (onChange)="loadCaregiverAppointments()"
          [options]="data.caregivers"
          [(ngModel)]="appointment.caregiver_id"
          optionValue="caregiver_id"
          optionLabel="caregiver_name"
          [required]="true"
          #caregiver="ngModel"
          [class.p-invalid]="
            caregiver.invalid && (caregiver.dirty || caregiver.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="date">{{
          appointment.with_interval ? "Termin Start" : "Termin für"
        }}</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon inputgroup-addon-day">{{
            getSelectedDay("date")
          }}</span>

          <p-calendar
            (onSelect)="setCheckedTimeData($event)"
            (onBlur)="setCheckedTimeData($event)"
            inputId="date"
            [showOtherMonths]="false"
            name="date"
            appendTo="body"
            dataType="string"
            dateFormat="dd.mm.yy"
            [firstDayOfWeek]="1"
            [showWeek]="true"
            [(ngModel)]="appointment.date"
            [required]="true"
            #date="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
          ></p-calendar>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="from">von</label>
        <div class="p-inputgroup">
          <span
            (click)="sub15Minutes('from', 'to')"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-arrow-left"></i
          ></span>
          <p-calendar
            (onBlur)="checkForTimes($event, 'from')"
            [stepMinute]="15"
            inputId="from"
            name="from"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="appointment.from"
            dataType="string"
            [required]="true"
            #from="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
          ></p-calendar>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="to" style="display: flex; justify-content: space-between"
          >bis
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                (onChange)="fullDay24hChanged()"
                name="full_day_24h"
                [binary]="true"
                [tabindex]="-1"
                label="24:00"
                [(ngModel)]="appointment.full_day_24h"
              ></p-checkbox>
            </div></div
        ></label>
        <div class="p-inputgroup">
          <input
            *ngIf="appointment.full_day_24h"
            pInputText
            disabled
            [value]="appointment.to"
          />
          <p-calendar
            *ngIf="!appointment.full_day_24h"
            (onBlur)="checkForTimes($event, 'to')"
            [stepMinute]="15"
            inputId="to"
            name="to"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="appointment.to"
            dataType="string"
            [required]="true"
            #to="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
          ></p-calendar>
          <span
            (click)="add15Minutes('from', 'to')"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-arrow-right"></i
          ></span>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="appointment.with_interval && !isEditMode"
    >
      <div class="p-field p-col-6">
        <label for="date_to">Termin bis</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon inputgroup-addon-day">{{
            getSelectedDay("date_to")
          }}</span>

          <p-calendar
            inputId="date_to"
            [showOtherMonths]="false"
            name="date_to"
            appendTo="body"
            dataType="string"
            dateFormat="dd.mm.yy"
            [firstDayOfWeek]="1"
            [showWeek]="true"
            [(ngModel)]="appointment.date_to"
            [required]="true"
            #date_to="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="
              date_to.invalid && (date_to.dirty || date_to.touched)
            "
          ></p-calendar>
        </div>
      </div>

      <div class="p-field p-col-6">
        <label for="interval">Rhythmus</label>
        <p-dropdown
          inputId="interval"
          name="interval"
          placeholder="Bitte auswählen"
          [options]="intervalOptions"
          [(ngModel)]="appointment.interval_type"
          (onChange)="setRhytmus()"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="appointment.with_interval && !isEditMode"
    >
      <div
        class="p-field p-col-12"
        style="padding-top: 7px"
        *ngIf="appointment.interval_type !== 'MONTHLY'"
      >
        <p-selectButton
          [options]="intervalDaysOptions"
          [(ngModel)]="appointment.interval_days"
          name="interval_days"
          [multiple]="true"
          [required]="true"
          #interval_days="ngModel"
          [class.p-invalid]="
            interval_days.invalid &&
            (interval_days.dirty || interval_days.touched)
          "
        >
        </p-selectButton>
      </div>
      <div
        class="p-field p-col-12"
        style="padding-top: 7px"
        *ngIf="appointment.interval_type === 'MONTHLY'"
      >
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Am {{ currentDay }}. Tag"
              name="monthly_type"
              [(ngModel)]="appointment.monthly_type"
              value="SAME_DAY"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="weekday"
              label=""
              name="monthly_type"
              [(ngModel)]="appointment.monthly_type"
              value="WEEK_DAY"
            >
            </p-radioButton>
            <label for="weekday"
              >Am

              <select
                class="select-field-light"
                (change)="appointment.monthly_type = 'WEEK_DAY'"
                [(ngModel)]="appointment.monthly_week"
                name="monthly_week"
              >
                <option [value]="1">ersten</option>
                <option [value]="2">zweiten</option>
                <option [value]="3">dritten</option>
                <option [value]="4">vierten</option>
                <option [value]="5">letzten</option>
              </select>

              {{ currentWeek }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        errors.time ||
        errors.holiday ||
        errors.time_split ||
        errors.time_quarter.length > 0
      "
    >
      <div
        class="p-field p-col-12"
        style="display: flex; flex-direction: column"
      >
        <span *ngIf="errors.time_split" class="color-alert"
          >Uhrzeit der Budgetaufteilung passt nicht zur Originaluhrzeit</span
        >

        <span *ngIf="errors.time_quarter.length > 0" class="color-alert"
          >Uhrzeit muss im 15-Minuten-Takt sein</span
        >

        <span *ngIf="errors.time" class="color-alert"
          >Plausibilität der Uhrzeit stimmt nicht</span
        >

        <span *ngIf="errors.holiday" class="color-alert"
          >Die Betreuungskraft arbeitet nicht an Feiertagen</span
        >
      </div>
    </div>
  </div>

  <div class="content-body without-padding budget-content-body">
    <p-table
      [value]="budgets"
      [rowHover]="true"
      [globalFilterFields]="[
            'name',
          ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 65px"></th>
          <th>Budget</th>
          <th>Info</th>
          <th>
            <div
              style="
                display: flex;
                justify-content: flex-end;
                font-weight: normal;
                text-transform: none;
                font-size: 14px;
              "
            >
              <p-checkbox
                *ngIf="isEditMode"
                label="Beihilfe"
                [binary]="true"
                [(ngModel)]="appointment.with_beihilfe"
                name="with_beihilfe"
              ></p-checkbox>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-budget>
        <tr class="clickable" (click)="appointment.budget_type = budget.type">
          <td>
            <div style="display: flex; gap: 15px; flex-shrink: 0">
              <p-radioButton
                label=""
                name="budget-type"
                [(ngModel)]="appointment.budget_type"
                [value]="budget.type"
                required
              ></p-radioButton>
            </div>
          </td>
          <td>
            {{ budget.type_formatted }}
          </td>

          <td colspan="2" *ngIf="budget.type === 'SELBSTZAHLER'">
            {{ budget.amount_hours_real_formatted }} Std geplant ({{
              data.patient.selbstzahler_budget_type
                ? data.patient.selbstzahler_budget_type === "Flexibel"
                  ? "Flexibel"
                  : data.patient.selbstzahler_budget_max + " Std Max"
                : "Keine Angabe"
            }})
          </td>
          <td colspan="2" *ngIf="budget.type === 'KOSTENTRAEGER'">
            {{ budget.amount_hours_real_formatted }} Std geplant /
            {{ weekHoursKostentraeger }} Std diese Woche
            <br />
            <small>
              Info: {{ data.patient.kostentraeger_budget || "??" }} Std / Woche,
              Gültig bis:
              {{
                data.patient.kostentraeger_valid_type === "Datum"
                  ? data.patient.kostentraeger_valid_until_date_string || "??"
                  : data.patient.kostentraeger_valid_type || "??"
              }}
            </small>
          </td>
          <td
            colspan="2"
            *ngIf="
              budget.type !== 'SELBSTZAHLER' && budget.type !== 'KOSTENTRAEGER'
            "
          >
            {{ budget.budget_end_formatted }} Rest
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-table
      *ngIf="appointment.split_budget"
      [value]="budgets"
      [rowHover]="true"
      [globalFilterFields]="[
            'name',
          ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 65px"></th>
          <th>Budget</th>
          <th>Info</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-budget>
        <tr class="clickable" (click)="appointment.budget_type_2 = budget.type">
          <td>
            <div style="display: flex; gap: 15px; flex-shrink: 0">
              <p-radioButton
                label=""
                name="budget-type-2"
                [(ngModel)]="appointment.budget_type_2"
                [value]="budget.type"
                required
              ></p-radioButton>
            </div>
          </td>
          <td>
            {{ budget.type_formatted }}
          </td>

          <td *ngIf="budget.type === 'SELBSTZAHLER'">
            {{ budget.amount_hours_real_formatted }} Std geplant ({{
              data.patient.selbstzahler_budget_type
                ? data.patient.selbstzahler_budget_type === "Flexibel"
                  ? "Flexibel"
                  : data.patient.selbstzahler_budget_max + " Std Max"
                : "Keine Angabe"
            }})
          </td>
          <td *ngIf="budget.type !== 'SELBSTZAHLER'">
            {{ budget.budget_end_formatted }} Rest
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div
    class="content-body without-padding budget-content-body"
    *ngIf="appointment.split_budget"
  >
    <div class="budget-times">
      <div class="budget-time">
        <div class="p-field p-col-6">
          <div class="p-inputgroup">
            <span
              (click)="sub15Minutes('from_1', 'to_1')"
              class="p-inputgroup-addon inputgroup-addon-clickable"
              ><i class="pi pi-arrow-left"></i
            ></span>
            <p-calendar
              (onBlur)="checkForTimes($event, 'from_1')"
              [stepMinute]="15"
              inputId="from-1"
              name="from-1"
              [timeOnly]="true"
              appendTo="body"
              [(ngModel)]="appointment.from_1"
              dataType="string"
              [required]="true"
              #from1="ngModel"
              [showButtonBar]="false"
              [class.p-invalid]="
                from1.invalid && (from1.dirty || from1.touched)
              "
            ></p-calendar>
          </div>
        </div>
        <div class="p-field p-col-6">
          <div class="p-inputgroup">
            <p-calendar
              (onBlur)="checkForTimes($event, 'to_1')"
              [stepMinute]="15"
              inputId="to-1"
              name="to-1"
              [timeOnly]="true"
              appendTo="body"
              [(ngModel)]="appointment.to_1"
              dataType="string"
              [required]="true"
              #to1="ngModel"
              [showButtonBar]="false"
              [class.p-invalid]="to1.invalid && (to1.dirty || to1.touched)"
            ></p-calendar>
            <span
              (click)="add15Minutes('from_1', 'to_1')"
              class="p-inputgroup-addon inputgroup-addon-clickable"
              ><i class="pi pi-arrow-right"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="budget-time">
        <div class="p-field p-col-6">
          <div class="p-inputgroup">
            <span
              (click)="sub15Minutes('from_2', 'to_2')"
              class="p-inputgroup-addon inputgroup-addon-clickable"
              ><i class="pi pi-arrow-left"></i
            ></span>
            <p-calendar
              (onBlur)="checkForTimes($event, 'from_2')"
              [stepMinute]="15"
              inputId="from-2"
              name="from-2"
              [timeOnly]="true"
              appendTo="body"
              [(ngModel)]="appointment.from_2"
              dataType="string"
              [required]="true"
              #from2="ngModel"
              [showButtonBar]="false"
              [class.p-invalid]="
                from2.invalid && (from2.dirty || from2.touched)
              "
            ></p-calendar>
          </div>
        </div>
        <div class="p-field p-col-6">
          <div class="p-inputgroup">
            <input
              *ngIf="appointment.full_day_24h"
              pInputText
              disabled
              [value]="appointment.to_2"
            />
            <p-calendar
              *ngIf="!appointment.full_day_24h"
              (onBlur)="checkForTimes($event, 'to_2')"
              [stepMinute]="15"
              inputId="to-2"
              name="to-2"
              [timeOnly]="true"
              appendTo="body"
              [(ngModel)]="appointment.to_2"
              dataType="string"
              [required]="true"
              #to2="ngModel"
              [showButtonBar]="false"
              [class.p-invalid]="to2.invalid && (to2.dirty || to2.touched)"
            ></p-calendar>
            <span
              (click)="add15Minutes('from_2', 'to_2')"
              class="p-inputgroup-addon inputgroup-addon-clickable"
              ><i class="pi pi-arrow-right"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment"
          >Bemerkung
          <i
            class="pi pi-info-circle color-gray"
            style="font-size: 12px; margin: 0 0 0 5px"
            tooltipStyleClass="tooltip-wider tooltip-wider-light"
            [pTooltip]="appointmentTask"
          ></i
        ></label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="appointment.comment"
          [rows]="1"
          placeholder="Optional"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div
            style="width: 33%"
            class="p-field-checkbox"
            *ngIf="appointment.change_required || keepAppointmentBefore"
          >
            <p-checkbox
              (onChange)="keepChanged()"
              label="Termin beibehalten"
              [binary]="true"
              [(ngModel)]="appointment.keep_appointment"
              name="keep_appointment"
            ></p-checkbox>
            <i
              style="margin: 0 0 0 5px; font-size: 13px"
              class="pi pi-info-circle color-gray"
              pTooltip="Prüfung nach Abwesenheiten (AU/U) werden übersprungen."
            ></i>
          </div>

          <div
            style="width: 33%"
            class="p-field-checkbox"
            *ngIf="appointment.change_required"
          >
            <p-checkbox
              (onChange)="notChangeableChanged()"
              label="Nicht umbuchbar"
              [binary]="true"
              [(ngModel)]="appointment.not_changeable"
              name="not_changeable"
            ></p-checkbox>
          </div>

          <div style="width: 33%" class="p-field-checkbox" *ngIf="!isEditMode">
            <p-checkbox
              [disabled]="appointment.split_budget"
              (onChange)="withIntervalToggle()"
              name="with_interval"
              [binary]="true"
              label="Wiederholen"
              [(ngModel)]="appointment.with_interval"
            ></p-checkbox>
          </div>

          <div style="width: 33%" class="p-field-checkbox" *ngIf="!isEditMode">
            <p-checkbox
              [disabled]="appointment.with_interval"
              label="Budget splitten"
              [binary]="true"
              (onChange)="budgetSplit()"
              [(ngModel)]="appointment.split_budget"
              name="split_budget"
            ></p-checkbox>
          </div>
        </div>

        <div class="p-formgroup-inline">
          <div style="width: 33%" class="p-field-checkbox">
            <p-checkbox
              label="Arzttermin"
              [binary]="true"
              [(ngModel)]="appointment.doctor_appointment"
              name="doctor_appointment"
            ></p-checkbox>
          </div>

          <div style="width: 33%" class="p-field-checkbox">
            <p-checkbox
              label="Einkaufsfahrt"
              [binary]="true"
              [(ngModel)]="appointment.shopping_appointment"
              name="shopping_appointment"
            ></p-checkbox>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12" *ngIf="appointment.not_changeable">
        <label for="not_changeable_reason">Weitere Informationen</label>
        <textarea
          placeholder="Warum nicht umbuchbar?"
          id="not_changeable_reason"
          name="not_changeable_reason"
          [(ngModel)]="appointment.not_changeable_reason"
          [rows]="1"
          pInputTextarea
          required
          #not_changeable_reason="ngModel"
          [class.p-invalid]="
            not_changeable_reason.invalid &&
            (not_changeable_reason.dirty || not_changeable_reason.touched)
          "
        ></textarea>
      </div>

      <div class="p-field p-col-12" *ngIf="appointment.not_changeable">
        <div class="p-field-checkbox">
          <p-checkbox
            label="Wunschtermin erstellen"
            [binary]="true"
            [(ngModel)]="appointment.store_desired_date"
            name="store_desired_date"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <div
      class="detail-information-banner"
      *ngFor="let check of errors.plausibleData"
      [class.warning-version]="check.plausible"
    >
      <span>{{ check.message }}</span>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data?.data?.id"></i>

    <button
      (click)="remove()"
      pButton
      [disabled]="hasInvoice"
      [pTooltip]="hasInvoice ? 'Einsatz wurde bereits abgerechnet' : ''"
      tooltipStyleClass="tooltip-wider"
      *ngIf="data?.data?.id"
      class="p-button-outlined"
      label="Einsatz stornieren"
      icon="pi pi-exclamation-triangle"
    ></button>

    <button
      *ngIf="!showPlausibleButton && (!appointment.with_interval || isEditMode)"
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted || submittedDelete"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>

    <button
      *ngIf="showPlausibleButton && (!appointment.with_interval || isEditMode)"
      pButton
      [label]="formularHasChanged() ? 'Speichern' : 'Trotzdem speichern'"
      type="submit"
      (click)="saveAppointment()"
      [disabled]="submitted || submittedDelete"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>

    <button
      *ngIf="!showPlausibleButton && appointment.with_interval && !isEditMode"
      pButton
      label="Termine prüfen"
      type="submit"
      (click)="checkIntervalAppointments()"
      [disabled]="submitted || submittedDelete"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>

    <button
      *ngIf="showPlausibleButton && appointment.with_interval && !isEditMode"
      pButton
      [label]="
        formularHasChanged() ? 'Termine prüfen' : 'Serientermine speichern'
      "
      type="submit"
      (click)="saveIntervalAppointments()"
      [disabled]="submitted || submittedDelete"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
