import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { TitleService } from '../../../services/title.service'
import { UserCustomerService } from '../../../services/user-customer.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddUserCustomerRequestDialogComponent } from '../../../components/dialogs/add-user-customer-request-dialog/add-user-customer-request-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-user-caregiver-appointments',
  templateUrl: './user-customer-appointments.component.html',
})
export class UserCustomerAppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null
  @ViewChild('dt2') table2: ElementRef | any = null

  public globalFilterValue = ''
  public loading = true
  private eventBusSubscription: Subscription = new Subscription()

  public data: { next_appointments: []; last_appointments: [] } = {
    next_appointments: [],
    last_appointments: [],
  }

  constructor(
    public userCustomerService: UserCustomerService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Meine Termine`)

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CustomerDateChanged:
            this.loadData(false)
        }
      }
    )
  }

  public openUserCustomerRequestDialog(request: any = null): void {
    this.dialogService.open(AddUserCustomerRequestDialogComponent, {
      header: request ? 'Anfrage bearbeiten' : 'Neue Anfrage',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!request,
        request,
      },
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
    this.table2 &&
      this.table2.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCustomerService.loadAppointments().subscribe((data: any) => {
      this.data = data

      this.loading = false
    })
  }
}
