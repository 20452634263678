<p-sidebar [(visible)]="showHistory" position="right">
  <h2>HISTORY</h2>

  <div class="dialog-sidebar-comments-container" style="margin: 20px 0 0 0">
    <div
      class="dialog-no-message dialog-no-comment"
      style="padding: 20px"
      *ngIf="histories.length === 0"
    >
      {{ historyLoaded ? "Keine Historie vorhanden" : "Lade Daten" }}
    </div>
    <div
      class="dialog-sidebar-comment selectable"
      style="padding: 10px 20px"
      *ngFor="let history of histories"
      (click)="openProofHistoryDialog(history)"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <strong>Kennzeichen: {{ history.cover_key || "Keine Angabe" }}</strong>

        <span
          *ngIf="history.upload_finished"
          class="p-tag p-tag-rounded p-tag-OTHER color-white"
          ><i class="pi pi-check"></i> GESPEICHERT</span
        >
      </div>
      <span>
        {{ history.amount_pages }}
        {{ history.amount_pages == 1 ? "Seite" : "Seiten" }} (<strong
          >{{ history.unedited_issue_items_count }} offene Fehler</strong
        >, {{ history.issue_pages }} Fehler gesamt)
      </span>
      <span>
        Hochgeladen von {{ history.created_from_user.name }} am
        {{ history.created_at | date: "dd.MM.y, H:mm" }} Uhr
      </span>
    </div>
  </div>
</p-sidebar>

<div class="content-actions">
  <i class="tio icon-large">file_text_outlined</i>

  <h2>
    <i class="tio">file_text_outlined</i>
    Leistungsnachweise
  </h2>

  <div class="content-actions-buttons">
    <button
      pButton
      label="History"
      (click)="loadHistory()"
      type="button"
      class="p-button-outlined"
      icon="pi pi-clock"
      iconPos="left"
    ></button>

    <button
      pButton
      (click)="openGenerateProofCoverDialog()"
      label="Deckblatt"
      type="button"
      class="p-button-outlined"
      icon="pi pi-file"
      iconPos="left"
    ></button>

    <button
      pButton
      (click)="openUploadProofsDialog()"
      label="Hochladen"
      class="p-button p-button-info"
      type="button"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </div>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i
        style="padding: 10px 15px; margin: 0 0 0 -20px"
        (click)="goToPreviousMonth()"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadData(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        style="padding: 10px 15px"
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
      ></i>
      <p-dropdown
        [style]="{ width: '140px' }"
        (onChange)="loadData(false)"
        [showClear]="true"
        name="planner_id"
        inputId="planner_id"
        [(ngModel)]="selectedPlanner"
        placeholder="Betreuer"
        [options]="plannerUsers"
        optionValue="id"
        optionLabel="full_name"
        scrollHeight="300px"
      ></p-dropdown>

      <p-dropdown
        [style]="{ width: '190px', margin: '0 0 0 -1px' }"
        scrollHeight="400px"
        [filter]="true"
        [showClear]="true"
        inputId="caregivers"
        name="caregivers"
        placeholder="Betreuungskraft"
        [resetFilterOnHide]="true"
        [options]="caregivers_active"
        [(ngModel)]="currentCaregiverId"
        optionValue="id"
        optionLabel="last_first_name"
        (onChange)="loadData(false)"
      ></p-dropdown>

      <p-treeSelect
        [filter]="true"
        [filterInputAutoFocus]="true"
        (onNodeSelect)="loadData(false)"
        (onNodeUnselect)="loadData(false)"
        (onClear)="loadData(false)"
        class="proofs-treeselect"
        [style]="{ margin: '0 0 0 -1px' }"
        selectionMode="checkbox"
        [showClear]="true"
        inputId="cities"
        name="cities"
        placeholder="Bezirke"
        [(ngModel)]="selectedCities"
        [options]="dependencies.counties"
        scrollHeight="500px"
      ></p-treeSelect>
    </span>

    <div class="content-header-buttons">
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <header class="content-header content-header-smaller">
    <span class="sub-content-header">
      <p-checkbox
        label="Fehlende LNW"
        [(ngModel)]="noProofs"
        (onChange)="filterChanged('noProofs')"
        [binary]="true"
        name="noProofs"
      ></p-checkbox>

      <p-checkbox
        label="Mehrere LNW"
        [binary]="true"
        (onChange)="filterChanged('multipleProofs')"
        [(ngModel)]="multipleProofs"
        name="multipleProofs"
      ></p-checkbox>

      <p-checkbox
        label="Unbestätigte Stunden"
        [binary]="true"
        (onChange)="filterChanged('unreleased')"
        [(ngModel)]="unreleased"
        name="unreleased"
      ></p-checkbox>

      <p-checkbox
        label="Nachbearbeitung"
        [binary]="true"
        (onChange)="filterChanged('reworking')"
        [(ngModel)]="reworking"
        name="reworking"
      ></p-checkbox>

      <p-checkbox
        label="Nur GKV"
        [binary]="true"
        (onChange)="filterChanged('onlyGv')"
        [(ngModel)]="onlyGv"
        name="onlyGv"
      ></p-checkbox>

      <p-checkbox
        label="Nur Rechnungsentwurf"
        [binary]="true"
        (onChange)="filterChanged('onlyInvoiceDraft')"
        [(ngModel)]="onlyInvoiceDraft"
        name="onlyInvoiceDraft"
      ></p-checkbox>
    </span>
  </header>
  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="data.length == 0"
  >
    <span class="content-no-message">Keine Einsätze vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="data.length > 0"
  >
    <p-table
      dataKey="key"
      #dt
      scrollHeight="100%"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [value]="data"
      [globalFilterFields]="[
        'patient_id',
        'patient_first_name',
        'patient_last_name',
        'patient_full_name',
        'patient_zipcode',
        'patient_city',
        'persplan_budgets.persplan_function',
        'proof_status.cover_key',
      ]"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 50px"></th>
          <th style="max-width: 300px" pSortableColumn="patient_last_name">
            Patient <p-sortIcon field="patient_last_name"></p-sortIcon>
          </th>
          <th style="max-width: 140px" pSortableColumn="patient_zipcode">
            PLZ <p-sortIcon field="patient_zipcode"></p-sortIcon>
          </th>
          <th pSortableColumn="persplan_budgets.persplan_function">
            Leistungsart
            <p-sortIcon field="persplan_budgets.persplan_function"></p-sortIcon>
          </th>
          <th pSortableColumn="persplan_budgets.hours_released">
            <div style="display: flex; align-items: center; gap: 5px">
              Bestätigte Stunden
              <span *ngIf="amountHours">({{ amountHoursFormatted }})</span>
              <p-sortIcon field="persplan_budgets.hours_released"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="proofs_count">
            Leistungsnachweise <p-sortIcon field="proofs_count"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded">
        <tr
          class="clickable"
          [class.background-hightlight-orange]="
            item.proof_status?.opened_reworkings_count > 0
          "
        >
          <td [pRowToggler]="item" style="max-width: 50px">
            <i
              class="pi"
              [class.pi-chevron-right]="!expanded"
              [class.pi-chevron-down]="expanded"
            ></i>
          </td>
          <td style="max-width: 300px" (click)="openProofCheckDialog(item)">
            <a class="color-main1" routerLink="/patients/{{ item.patient_id }}"
              >{{ item.patient_last_name }}, {{ item.patient_first_name }}</a
            >
          </td>
          <td style="max-width: 140px" (click)="openProofCheckDialog(item)">
            {{ item.patient_zipcode }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.persplan_budgets.persplan_function }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            <div
              style="display: flex; align-items: center; gap: 5px"
              class="color-main1"
              *ngIf="
                item.persplan_budgets.hours_released ===
                item.persplan_budgets.hours_amount
              "
            >
              <i style="font-size: 13px" class="pi pi-check"></i>
              {{ item.persplan_budgets.hours_released_formatted }} Stunden
            </div>
            <div
              *ngIf="
                item.persplan_budgets.hours_released !==
                item.persplan_budgets.hours_amount
              "
            >
              {{ item.persplan_budgets.hours_released_formatted }} /
              {{ item.persplan_budgets.hours_amount_formatted }} Stunden
            </div>
          </td>
          <td (click)="openProofCheckDialog(item)">
            <div
              class="color-orange"
              style="display: flex; align-items: center; gap: 5px"
              *ngIf="item.proofs_count > 1"
            >
              <i class="pi pi-exclamation-triangle"></i>
              {{ item.proofs_count }} LNW
            </div>

            <div
              class="color-main1"
              style="display: flex; align-items: center; gap: 5px"
              *ngIf="item.proofs_count == 1"
            >
              <i class="pi pi-check"></i> 1 LNW
            </div>

            <div
              style="display: flex; align-items: center; gap: 5px"
              *ngIf="item.proofs_count == 0"
            >
              0 LNW
            </div>
          </td>
          <td style="max-width: 50px">
            <i
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider tooltip-wider-light"
              [pTooltip]="item.proof_status?.opened_reworkings[0].reason"
              class="pi pi-exclamation-triangle color-orange"
              *ngIf="item.proof_status?.opened_reworkings_count > 0"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-item>
        <tr>
          <td style="padding: 0" colspan="12">
            <p-table [value]="item.persplan_budgets.appointments" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th
                    class="background-hightlight-gray"
                    style="max-width: 50px"
                  ></th>
                  <th
                    class="background-hightlight-gray"
                    style="max-width: 300px"
                  >
                    Betreuungskraft
                  </th>
                  <th
                    class="background-hightlight-gray"
                    style="max-width: 140px"
                  >
                    Datum
                  </th>
                  <th
                    class="background-hightlight-gray"
                    style="max-width: 140px"
                  >
                    Uhrzeit
                  </th>
                  <th
                    class="background-hightlight-gray"
                    style="max-width: 140px"
                  >
                    Dauer
                  </th>
                  <th class="background-hightlight-gray">Leistungsart</th>
                  <th class="background-hightlight-gray"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-appointment>
                <tr>
                  <td style="max-width: 50px">
                    <i
                      class="pi pi-flag-fill color-gray"
                      *ngIf="appointment.keep_appointment"
                      pTooltip="Termin beibehalten"
                      style="font-size: 13px"
                    ></i>

                    <i
                      class="pi pi-flag-fill color-orange"
                      *ngIf="appointment.calculate_appointment"
                      pTooltip="Termin storniert"
                      style="font-size: 13px"
                    ></i>
                  </td>
                  <td style="max-width: 300px">
                    <a
                      target="_blank"
                      class="color-main1"
                      routerLink="/caregivers/{{ appointment.caregiver_id }}"
                      >{{ appointment.caregiver_name }}</a
                    >
                  </td>
                  <td style="max-width: 140px">{{ appointment.date }}</td>
                  <td style="max-width: 140px">
                    {{ appointment.from_h }} - {{ appointment.to_h }}
                  </td>
                  <td
                    [class.color-main1]="
                      !appointment.from_admin && appointment.released_id
                    "
                    [class.color-blue]="appointment.from_admin"
                    style="max-width: 140px"
                  >
                    <div style="display: flex; align-items: center; gap: 5px">
                      <i
                        *ngIf="appointment.released_id"
                        style="font-size: 13px"
                        class="pi pi-check"
                      ></i>
                      {{ appointment.hours }} Std
                    </div>
                  </td>
                  <td>
                    {{ appointment.persplan_function_complete }}
                  </td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
