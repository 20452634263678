<app-loader [loading]="loading"></app-loader>

<div
  class="content"
  *ngIf="!loading"
  pDroppable="caregiver-planning"
  (onDragEnter)="contentEntered()"
  (onDragLeave)="contentLeaved()"
>
  <header class="content-header is-sticky">
    <span>
      <i
        style="padding: 10px 15px; margin: 0 0 0 -20px"
        (click)="goToPreviousMonth()"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadData(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        appendTo="body"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        appendTo="body"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="padding: 10px 15px"
      ></i>
    </span>

    <span *ngIf="statistics.analyses && !contentLoading">
      <span
        >{{ data.amount_patients.planned_for_next_month }} /
        {{ data.amount_patients.current_patients }} geplant</span
      >
      <i
        (click)="op.toggle($event)"
        class="pi pi-info-circle clickable-pi-icon"
      ></i>

      <p-overlayPanel
        #op
        styleClass="with-border"
        [showCloseIcon]="true"
        [style]="{
          maxWidth: '400px',
          width: 'max-content',
          maxHeight: '420px',
          overflowY: 'auto'
        }"
      >
        <ng-template pTemplate>
          <div [innerHTML]="data.amount_patients.not_planned_patients"></div>
        </ng-template>
      </p-overlayPanel>
    </span>

    <span
      class="header-small-infos"
      *ngIf="statistics.analyses && !contentLoading"
    >
      <span
        ><small>Stunden</small>
        {{ statistics.analyses.times.WORKING_TIME_SUM.months[0].value }}
        / {{ statistics.analyses.times.SOLL.months[0].value }}</span
      >
      <span><small>Überstunden</small> {{ statistics.overtime }}</span>
      <span
        ><small>Aufgelaufen</small>
        {{ statistics.analyses.times.ACCUMULATED.months[0].value }}</span
      >
      <span
        ><small>Stellenumfang</small>
        {{ statistics.jobScope?.job_scope || "-" }}</span
      >
    </span>
  </header>
  <div
    class="content-body without-padding no-transition"
    [class.content-loading]="contentLoading"
  >
    <div class="time-month">
      <div class="time-category"></div>

      <div class="time-months">
        <div
          [class.hover-highlight]="
            currentTimeHover === time.value.substring(0, 2)
          "
          *ngFor="let time of data.times"
        >
          <strong>{{ time.value }}</strong>
        </div>
      </div>
    </div>

    <div class="time-lists">
      <div
        class="time-list"
        [class.background-hightlight-blue]="item.dates.TYPE_VACATION"
        [class.background-hightlight-gray]="item.is_weekend"
        [class.background-hightlight-alert]="item.dates.TYPE_ILL"
        [class.background-hightlight-yellow]="item.holiday"
        [class.hover-highlight]="currentHover === item.date"
        [class.is-sunday]="item.is_sunday"
        *ngFor="let item of data.list"
      >
        <div class="time-category" (mouseenter)="checkForCopiedAppointment()">
          <div
            class="drive-time-container"
            *ngIf="driveTimeForDayIsLoading === item.date"
          >
            <i
              class="pi pi-spin pi-spinner color-white"
              *ngIf="!driveTimeLoadedTime"
            ></i>
            <span *ngIf="driveTimeLoadedTime">{{ driveTimeLoadedTime }}</span>
          </div>

          <i
            pTooltip="Kopie einfügen"
            (click)="openCopyDialog(item.date)"
            *ngIf="hasCopiedAppointment && !item.is_sunday"
            class="date-copy-container"
            ><i class="pi pi-sync"></i
          ></i>

          <div>
            <span *ngIf="!item.is_sunday">{{ item.date }}</span>
            <small>{{ item.day }}</small>
          </div>

          <div>
            <i
              class="pi pi-sun"
              *ngIf="item.holiday"
              [pTooltip]="item.holiday"
            ></i>
            <i
              class="pi pi-sun color-blue"
              *ngIf="item.dates.TYPE_VACATION"
              [pTooltip]="item.dates.TYPE_VACATION.tooltip"
            ></i>
            <i
              class="pi pi-heart color-alert"
              *ngIf="item.dates.TYPE_ILL"
              [pTooltip]="item.dates.TYPE_ILL.tooltip"
            ></i>
            <i
              class="pi pi-sun color-main1"
              *ngIf="item.dates.TYPE_OTHER"
              [pTooltip]="item.dates.TYPE_OTHER.tooltip"
            ></i>
            <i
              class="pi pi-sun color-main1"
              *ngIf="item.dates.TYPE_FREE_TIME_REQUEST"
              [pTooltip]="item.dates.TYPE_FREE_TIME_REQUEST.tooltip"
            ></i>
            <i
              class="pi pi-book color-main1"
              *ngIf="item.dates.TYPE_TRAINING"
              [pTooltip]="item.dates.TYPE_TRAINING.tooltip"
            ></i>
            <i
              class="pi pi-home"
              *ngIf="item.dates.TYPE_INTERN"
              [pTooltip]="item.dates.TYPE_INTERN.tooltip"
            ></i>
          </div>
        </div>
        <div class="times-container-wrapper">
          <div class="times-container-drop">
            <div
              pDroppable="caregiver-planning"
              class="times-empty"
              [class.no-hover]="item.is_sunday"
              [title]="time.value"
              (click)="
                item.is_sunday
                  ? null
                  : openCaregiverPlanningDialog(
                      null,
                      'NEW',
                      time.value,
                      item.date
                    )
              "
              [class.background-main2]="
                item.day == currentHighlightDay &&
                time.value == currentHighlightTime
              "
              *ngFor="let time of data.quarter_times"
              (onDragEnter)="
                item.is_sunday ? null : dragEnter($event, item.date, time.value)
              "
              (onDrop)="
                item.is_sunday ? null : onDrop($event, time.value, item.date)
              "
            ></div>
          </div>

          <ng-container *ngFor="let dataItem of item.data">
            <!-- Abwesenheit -->
            <div
              *ngIf="
                dataItem.type !== 'TYPE_APPOINTMENT' &&
                dataItem.type !== 'TYPE_DRIVE_TIME'
              "
              [attr.data-date-id]="dataItem.id"
              pDraggable="caregiver-planning"
              (onDragStart)="dragStart(dataItem, item, 'date')"
              (onDragEnd)="dragEnd()"
              class="times-wrapper"
              style="z-index: 10; width: calc({{
                dataItem.width_percentage
              }}%; left: {{ dataItem.left_percentage }}%"
            >
              <div
                (click)="openDateDialog(dataItem)"
                *ngIf="dataItem.type === 'TYPE_INTERN'"
                class="times times-{{ dataItem.type }}"
              >
                <span>{{ dataItem.type_string }}</span>
              </div>

              <div
                (click)="openDateDialog(dataItem)"
                *ngIf="dataItem.type !== 'TYPE_INTERN'"
                class="times times-{{ dataItem.type }}"
                [title]="dataItem.tooltip"
              >
                <span>{{ dataItem.value }}</span>
              </div>

              <i
                *ngIf="dataItem.type === 'TYPE_PLANNING_FOLLOWS'"
                (click)="copyDate(dataItem.id)"
                pTooltip="Kopieren"
                class="date-copy-container"
                ><i class="pi pi-sync"></i
              ></i>
            </div>

            <!-- Fahrzeit -->
            <div
              *ngIf="dataItem.type === 'TYPE_DRIVE_TIME'"
              class="times-wrapper"
              style="z-index: 10; width: 24px; left: calc({{
                dataItem.left_percentage
              }}% {{ dataItem.last_appointment ? ' + 2px' : ' - 26px' }})"
            >
              <div
                style="padding: 0; text-align: center"
                class="times not-clickable times-TYPE_DRIVE_TIME"
                title="Minuten Fahrzeit"
              >
                <!--                <i class="pi pi-arrow-right"></i>-->
                <span style="font-size: 11px">{{ dataItem.value }} </span>
              </div>
            </div>

            <!-- Termin -->
            <div
              [class.time-storno]="dataItem.data.has_storno_options"
              class="times-wrapper"
              [attr.data-id]="dataItem.data.id"
              *ngIf="dataItem.type === 'TYPE_APPOINTMENT'"
              pDraggable="caregiver-planning"
              (onDragStart)="dragStart(dataItem, item, 'appointment')"
              (onDragEnd)="dragEnd()"
              style="width: calc({{ dataItem.width_percentage }}%; left: {{
                dataItem.left_percentage
              }}%"
            >
              <div
                (click)="openCaregiverPlanningDialog(dataItem.data.id, 'EDIT')"
                class="times"
                [title]="dataItem.tooltip"
                [class.block-appointment-change-required]="
                  dataItem.data.change_required
                "
              >
                <span>
                  <i
                    pTooltip="Arzttermin"
                    *ngIf="dataItem.data.doctor_appointment"
                    class="pi pi-heart-fill transparent"
                  ></i>
                  <i
                    pTooltip="Einkaufsfahrt"
                    *ngIf="dataItem.data.shopping_appointment"
                    class="pi pi-shopping-cart transparent"
                  ></i>
                  <i
                    [pTooltip]="dataItem.data.storno_details"
                    *ngIf="dataItem.data.has_storno_options"
                    class="pi pi-exclamation-triangle transparent"
                  ></i>
                  <i
                    pTooltip="Termin beibehalten"
                    *ngIf="dataItem.data.keep_appointment"
                    class="pi pi-flag-fill transparent"
                  ></i>
                  <i *ngIf="dataItem.data.patient.status === 'NEW'">NK</i>
                  {{ dataItem.data.patient.last_name }},
                  {{ dataItem.data.patient.first_name }}
                </span>
                <small>{{ dataItem.budget_type_formatted }}</small>
              </div>

              <i
                (click)="copyAppointment(dataItem.data.id)"
                pTooltip="Kopieren"
                class="date-copy-container"
                ><i class="pi pi-sync"></i
              ></i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="time-month time-month-bottom">
      <div class="time-category"></div>

      <div class="time-months">
        <div
          [class.hover-highlight]="
            currentTimeHover === time.value.substring(0, 2)
          "
          *ngFor="let time of data.times"
        >
          <strong>{{ time.value }}</strong>
        </div>
      </div>
    </div>
  </div>
</div>
