<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">group_equal</i>
    Patienten
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>Übersicht aller Patienten</span>

    <div class="content-header-buttons">
      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height">
      <span
        class="content-no-message with-padding"
        *ngIf="patients.length === 0"
      >
        Keine Patienten vorhanden
      </span>

      <p-table
        [globalFilterFields]="[
          'id',
          'first_name',
          'last_name',
          'full_name',
          'city',
          'zipcode'
        ]"
        #dt
        [value]="patients"
        [rowHover]="true"
        *ngIf="patients.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="width: 160px" pSortableColumn="status_string">
              Status
              <p-sortIcon field="status_string"></p-sortIcon>
            </th>
            <th pSortableColumn="last_name">
              Name <p-sortIcon field="last_name"></p-sortIcon>
            </th>
            <th pSortableColumn="city">
              Wohnort <p-sortIcon field="city"></p-sortIcon>
            </th>
            <th pSortableColumn="customer.created_at">
              Erstellt am
              <p-sortIcon field="customer.created_at"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
          <tr>
            <td>
              <!-- Wenn Quali noch offen ist, soll der Workflow Status angezeigt werden -->
              <span
                *ngIf="!patient.status && !patient.is_paused"
                class="p-tag p-tag-rounded p-tag-{{ patient.workflow_status }}"
                >{{ patient.workflow_status_string }}</span
              >

              <!-- Falls der Patient pausiert ist -->
              <span
                *ngIf="patient.is_paused"
                class="p-tag p-tag-rounded p-tag-PAUSED"
                >Pausiert</span
              >

              <!-- Ansonsten soll der richtige Status angezeigt werden -->
              <span
                *ngIf="patient.status && !patient.is_paused"
                class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                >{{ patient.status_string }}
              </span>
            </td>
            <td>
              <a class="color-main1" routerLink="/patienten/{{ patient.id }}">
                {{ patient.last_name }}, {{ patient.first_name }}
              </a>
            </td>
            <td>
              <i
                style="font-size: 14px; margin: 3px 3px 0 0"
                class="pi pi-info-circle"
                *ngIf="patient.city"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city || "-" }}
            </td>
            <td>
              {{ patient.customer.created_at | date: "dd.MM.y, H:mm" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
