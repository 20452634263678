<div class="content-actions">
  <i class="tio icon-large">help_outlined</i>

  <h2>
    <i class="tio">help_outlined</i>
    Qualifizierung
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/patients"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Qualifizierung
  </a>
  <a
    routerLink="/patients/consultings"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Calls
  </a>
</header>

<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Patienten die noch nicht Qualifiziert sind</span>

    <div class="content-header-buttons">
      <a routerLink="/offer">
        <button
          pButton
          label="Neues Angebot"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
        *ngIf="patients.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="patients.length == 0">
    <span class="content-no-message"
      >Keine offenen Qualifizierungen vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="patients.length > 0"
  >
    <p-table
      #dt
      [value]="patients"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'full_name',
        'persplan_id',
        'city'
      ]"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Patienten"
      [(first)]="firstPage"
      scrollHeight="100%"
      [scrollable]="true"
      stateStorage="local"
      stateKey="state-patients-qualification-open-3"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th
            pTooltip="Ungesehener Kunde von Multi, BK oder Kampagne"
            style="max-width: 80px"
            pSortableColumn="customer.unseen_from_lead"
          >
            Neu <p-sortIcon field="customer.unseen_from_lead"></p-sortIcon>
          </th>
          <th style="max-width: 80px" pSortableColumn="from_campaign">
            Lead <p-sortIcon field="from_campaign"></p-sortIcon>
          </th>
          <th
            style="max-width: 80px"
            pSortableColumn="customer.consulting_assignment"
          >
            BE <p-sortIcon field="customer.consulting_assignment"></p-sortIcon>
          </th>
          <th style="max-width: 80px" pSortableColumn="customer.offer_type">
            NK-Call <p-sortIcon field="customer.offer_type"></p-sortIcon>
          </th>
          <th style="width: 160px" pSortableColumn="workflow_status">
            Status <p-sortIcon field="workflow_status"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th style="width: 220px" pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th
            style="width: 140px"
            pSortableColumn="customer.qm.initial_consultation_date"
          >
            Beratung
            <p-sortIcon
              field="customer.qm.initial_consultation_date"
            ></p-sortIcon>
          </th>
          <th pSortableColumn="customer.qm.initial_consultation_type">
            von
            <p-sortIcon
              field="customer.qm.initial_consultation_type"
            ></p-sortIcon>
          </th>
          <th pSortableColumn="last_offer.created_at">
            Versand
            <p-sortIcon field="last_offer.created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.qm.in_progress_since">
            HC
            <p-sortIcon field="customer.qm.in_progress_since"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.created_at">
            Erstellt am
            <p-sortIcon field="customer.created_at"></p-sortIcon>
          </th>

          <!--          <th style="width: 50px"></th>-->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr
          [class.background-hightlight-main2]="
            patient.customer.created_from_multiplier ||
            patient.customer.created_from_caregiver
          "
        >
          <td style="max-width: 80px">
            <span
              pTooltip="Ungesehener Kunde von Multi, BK oder Kampagne"
              *ngIf="patient.customer.unseen_from_lead"
              class="p-tag p-tag-rounded p-tag-OPEN"
              >NEU</span
            >
          </td>
          <td style="max-width: 80px">
            <i
              class="light-symbol light-symbol-blue"
              pTooltip="Aus Kampagne"
              *ngIf="patient.from_campaign"
            ></i>
          </td>
          <td style="max-width: 80px">
            <span
              *ngIf="patient.customer.consulting_assignment"
              class="p-tag p-tag-rounded p-tag-APPLICANT"
              >BE</span
            >
          </td>
          <td style="max-width: 80px">
            <span
              *ngIf="patient.customer.offer_type === 'CALL'"
              class="p-tag p-tag-rounded p-tag-NEW"
              >NK-Call</span
            >
          </td>
          <td style="width: 160px">
            <span
              class="p-tag p-tag-rounded p-tag-{{ patient.workflow_status }}"
              >{{ patient.workflow_status_string }}</span
            >
          </td>
          <td style="min-width: 200px">
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td style="width: 220px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle"
                *ngIf="patient.city"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city || "-" }}
            </div>
          </td>

          <td style="width: 140px">
            {{ patient.customer.qm.initial_consultation_date_string || "-" }}
          </td>

          <td *ngIf="!patient.customer.qm.initial_consultation_type">-</td>
          <td *ngIf="patient.customer.qm.initial_consultation_type">
            <div class="text-center-container">
              <i
                class="pi pi-phone color-gray"
                style="font-size: 14px"
                pTooltip="Telefonisch"
                *ngIf="
                  patient.customer.qm.initial_consultation_type ===
                  'Telefonisch'
                "
              ></i>
              <i
                class="pi pi-user color-gray"
                style="font-size: 14px"
                pTooltip="Persönlich"
                *ngIf="
                  patient.customer.qm.initial_consultation_type === 'Persönlich'
                "
              ></i>
              {{
                patient.customer.qm.initial_consultation_from
                  ? patient.customer.qm.initial_consultation_from
                      .replace("Herr", "")
                      .replace("Frau", "") || "?"
                  : ""
              }}
            </div>
          </td>

          <td *ngIf="!patient.last_offer">-</td>
          <td *ngIf="patient.last_offer">
            <div style="display: flex; flex-direction: column">
              <div class="text-center-container">
                <i
                  class="pi pi-send color-gray"
                  pTooltip="E-Mail"
                  *ngIf="patient.last_offer.type_string === 'E-Mail'"
                ></i>
                <i
                  class="pi pi-file color-gray"
                  pTooltip="Post"
                  *ngIf="patient.last_offer.type_string === 'Post'"
                ></i>
                {{ patient.last_offer.sending_type_string }}
              </div>
              <small class="color-gray"
                >{{ patient.last_offer.created_at | date: "dd.MM.y H:mm"
                }}<br />
                {{ patient.last_offer.history.created_from_name }}</small
              >
            </div>
          </td>
          <td>
            <div class="text-center-container">
              <i
                *ngIf="
                  !patient.customer.qm.in_progress &&
                  patient.customer.qm.home_visit_date
                "
                class="pi pi-clock color-gray"
                pTooltip="Geplant"
              ></i>

              <i
                *ngIf="patient.customer.qm.in_progress"
                class="pi pi-check color-blue"
                pTooltip="In Bearbeitung"
              ></i>

              <span
                *ngIf="
                  !patient.customer.qm.in_progress &&
                  patient.customer.qm.home_visit_date
                "
              >
                {{ patient.customer.qm.home_visit_date | date: "dd.MM.y, H:mm"
                }}<br />
                <small class="color-gray">
                  {{ patient.customer.qm.home_visit_from || "?" }}
                </small></span
              >
              <span *ngIf="patient.customer.qm.in_progress">
                {{ patient.customer.qm.home_visit_date | date: "dd.MM.y, H:mm"
                }}<br />
                <small class="color-gray">
                  von {{ patient.customer.qm.in_progress_from || "?" }}
                </small>
              </span>
              <span
                *ngIf="
                  !patient.customer.qm.in_progress &&
                  !patient.customer.qm.home_visit_date
                "
                >-</span
              >
            </div>
          </td>
          <td style="width: 140px">
            {{ patient.customer.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <!--          <td>-->
          <!--            <i-->
          <!--              (click)="openSendDlvDialog(patient)"-->
          <!--              pTooltip="DLV versenden"-->
          <!--              class="pi pi-send"-->
          <!--            ></i>-->
          <!--          </td>-->
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
