<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <i class="tio icon-large">star_outlined</i>

  <h2>
    <i class="tio">star_outlined</i>
    Meine Anfragen
  </h2>

  <button
    pButton
    (click)="openUserCustomerRequestDialog()"
    label="Neue Anfrage"
    class="p-button"
    type="button"
    icon="pi pi-star"
    iconPos="left"
  ></button>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <ng-container>
    <div class="content-body without-padding auto-height">
      <span class="content-no-message with-padding" *ngIf="data.length === 0">
        Keine Anfragen vorhanden
      </span>

      <p-table
        [value]="data"
        [rowHover]="true"
        responsiveLayout="scroll"
        *ngIf="data.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="width: 120px" pSortableColumn="process_status">
              Status <p-sortIcon field="process_status"></p-sortIcon>
            </th>
            <th style="max-width: 150px" pSortableColumn="date_type">
              Typ <p-sortIcon field="date_type"></p-sortIcon>
            </th>
            <th style="max-width: 150px" pSortableColumn="from">
              Datum
              <p-sortIcon field="from"></p-sortIcon>
            </th>
            <th style="max-width: 150px">Uhrzeit</th>
            <th>Details</th>
            <th style="max-width: 200px" pSortableColumn="created_at">
              Erstellt am
              <p-sortIcon field="created_at"></p-sortIcon>
            </th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="clickable" (click)="openUserCustomerRequestDialog(item)">
            <td style="width: 120px">
              <span class="p-tag p-tag-rounded p-tag-{{ item.process_status }}">
                {{ item.process_status_string }}
              </span>
            </td>
            <td>
              {{ item.date_type_string }}
            </td>

            <td *ngIf="item.from != item.to">
              {{ item.from | date: "dd.MM.y" }} -
              {{ item.to | date: "dd.MM.y" }}
            </td>
            <td *ngIf="item.from == item.to">
              {{ item.from | date: "dd.MM.y" }}
            </td>

            <td>{{ item.from_time }} - {{ item.to_time }}</td>
            <td>
              {{ item.comment || "-" }}
            </td>
            <td>{{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>
            <td class="table-action" style="width: 100px">
              <i
                tooltipPosition="left"
                pTooltip="Bearbeiten"
                class="pi pi-pencil show-always"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
