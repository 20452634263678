<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Kunde"
            name="type"
            [(ngModel)]="phonecall.phone_caller_type"
            value="customer"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Betreuungskraft"
            name="type"
            [(ngModel)]="phonecall.phone_caller_type"
            value="caregiver"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Multi"
            name="type"
            [(ngModel)]="phonecall.phone_caller_type"
            value="multiplier"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Sonstiges"
            name="type"
            [(ngModel)]="phonecall.phone_caller_type"
            value="other"
          ></p-radioButton>
        </div>
      </div>
    </div>
    <div class="p-field" *ngIf="phonecall.phone_caller_type !== 'other'">
      <label for="id" *ngIf="phonecall.phone_caller_type !== 'customer'"
        >ID</label
      >
      <label for="id" *ngIf="phonecall.phone_caller_type === 'customer'"
        >ID Kunde (nicht die ID vom Patienten)</label
      >
      <input
        id="id"
        name="id"
        [(ngModel)]="phonecall.phone_caller_id"
        type="text"
        pInputText
      />
    </div>
    <div class="p-field">
      <label for="name">Name</label>
      <input
        id="name"
        name="name"
        [(ngModel)]="phonecall.phone_caller_name"
        type="text"
        #name="ngModel"
        pInputText
        required
        [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
      />
      <div
        *ngIf="name.invalid && (name.dirty || name.touched)"
        class="p-invalid"
      >
        <div *ngIf="name.errors?.required">
          <small>Bitte geben Sie einen Namen ein</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="phonecall.phone_caller_type === 'caregiver'">
      <label for="patient">Patient</label>
      <p-autoComplete
        field="full_name"
        inputId="patient"
        name="patient"
        placeholder="Optional"
        [forceSelection]="true"
        [(ngModel)]="phonecall.patient"
        [suggestions]="patients"
        (completeMethod)="searchPatients($event)"
      >
        <ng-template let-patient pTemplate="item">
          <div class="search-result-data">
          <span class="search-result-name">
            <b>{{ patient.full_name }}</b>
            <span class="search-result-id">#{{ patient.id }}</span>
            <i
              *ngIf="patient.status"
              class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
            >{{ patient.status_string }}</i
            >
          </span>
            <span class="search-result-address">
            {{ patient.zipcode }} {{ patient.city }}
          </span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>

    <div class="p-field">
      <label for="comment">Gesprächsthema <i class="pi pi-info-circle" pTooltip="Nur der Ersteller, oder ein Benutzer mit dem Recht, darf das Gespräch bearbeiten."></i></label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="phonecall.comment"
        type="text"
        [rows]="8"
        [readOnly]="!canEdit"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field-checkbox" style="margin: 10px 0 0 0">
      <p-checkbox
        *ngIf="phonecall.phone_caller_type !== 'other'"
        label="Wichtiges Telefonat"
        [binary]="true"
        [(ngModel)]="phonecall.history.important"
        name="important"
      ></p-checkbox>

      <p-checkbox
        label="Weiterleitung"
        [binary]="true"
        [style]="{ margin: '0 0 0 20px' }"
        [(ngModel)]="phonecall.is_redirect"
        name="is_redirect"
      ></p-checkbox>

      <p-checkbox
        label="Nicht erreicht"
        [binary]="true"
        [style]="{ margin: '0 0 0 20px' }"
        [(ngModel)]="phonecall.not_reached"
        name="not_reached"
      ></p-checkbox>
    </div>

    <div class="p-field-checkbox" style="margin: 10px 0 0 0">
      <p-checkbox
        label="Neukunden Call"
        [binary]="true"
        [(ngModel)]="phonecall.is_consulting"
        name="is_consulting"
      ></p-checkbox>

      <p-checkbox
        *ngIf="phonecall.is_consulting"
        label="Starkes Interesse"
        [style]="{ margin: '0 0 0 20px' }"
        [binary]="true"
        [(ngModel)]="phonecall.strong_interest"
        name="strong_interest"
      ></p-checkbox>
    </div>

    <div
      class="p-field"
      style="margin: 20px 0 0 0"
      *ngIf="phonecall.is_consulting"
    >
      <label for="attention_from">Aufmerksam durch</label>
      <p-dropdown
        inputId="attention_from"
        name="attention_from"
        placeholder="Bitte auswählen"
        appendTo="body"
        [options]="attentionFrom"
        [(ngModel)]="phonecall.attention_from_id"
        optionLabel="name"
        optionValue="id"
      ></p-dropdown>
    </div>

    <!-- TO-DO Zeug -->
    <div
      class="p-field-checkbox"
      style="margin-top: 50px"
      *ngIf="todo.is_possible"
    >
      <p-checkbox
        name="todo"
        [binary]="true"
        [(ngModel)]="todo.create"
        label="Todo erstellen"
      ></p-checkbox>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="todo.create">
      <div class="p-field p-col-12">
        <label for="title">
          Titel *
        </label>
        <input
          id="title"
          name="title"
          type="text"
          [(ngModel)]="todo.title"
          pInputText
          #title="ngModel"
          [required]="true"
          [class.p-invalid]="title.invalid && (title.dirty || title.touched)"
        />
      </div>

      <div class="p-field p-col-12">
        <label for="category">Kategorie</label>
        <p-dropdown
          [showClear]="true"
          appendTo="body"
          scrollHeight="500px"
          placeholder="Optional"
          inputId="category"
          name="category"
          optionLabel="category"
          optionValue="id"
          [options]="todoCategories"
          [(ngModel)]="todo.category_id"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-8">
        <label for="assignments">Zuweisung an *</label>
        <p-multiSelect
          selectedItemsLabel="{0} Benutzer ausgewählt"
          inputId="assignments"
          [options]="userSystemOptions"
          optionLabel="full_name"
          name="assignments"
          [filter]="true"
          [(ngModel)]="todo.user_systems"
          appendTo="body"
          [required]="true"
        ></p-multiSelect>
      </div>

      <div class="p-field p-col-4">
        <label for="priority">Priorität *</label>
        <p-dropdown
          inputId="priority"
          name="priority"
          [options]="priorityOptions"
          [(ngModel)]="todo.priority"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field" style="margin-top: 15px" *ngIf="todo.create">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen bis"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_UNTIL"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird immer im Dashboard angezeigt"
          ></i>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen am"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_AT"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird ab dem Zeitpunkt im Dashboard angezeigt"
          ></i>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="todo.create">
      <p-calendar
        [firstDayOfWeek]="1"
        [showWeek]="true"
        inputId="for_when"
        name="for_when"
        [showButtonBar]="true"
        [(ngModel)]="todo.for_when_string"
        dateFormat="dd.mm.yy"
        appendTo="body"
        dataType="string"
        [showIcon]="true"
        [required]="true"
        #for_when="ngModel"
        [class.p-invalid]="
          for_when.invalid && (for_when.dirty || for_when.touched)
        "
      ></p-calendar>
      <div
        *ngIf="for_when.invalid && (for_when.dirty || for_when.touched)"
        class="p-invalid"
      >
        <div *ngIf="for_when.errors?.required">
          <small>Bitte wählen Sie ein Datum</small>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <button
      pButton
      (click)="save()"
      label="Speichern"
      type="submit"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
