import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { DesiredDateService } from '../../../services/desired-date.service'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-add-desired-date-dialog',
  templateUrl: './add-desired-date-dialog.component.html',
})
export class AddDesiredDateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public patients: SearchResultModel[] = []
  public caregivers: SearchResultModel[] = []

  public dateInfo = {
    kw: '',
    day: '',
  }

  public data: any = {}
  public values = {
    times: [
      {
        no_time: false,
        date_at: '',
        time_from: '',
        time_to: '',
      },
    ] as any,
    no_time: false,
    time_from: '',
    time_to: '',
    date_at: '',
    comment: '',
    type: 'NEW',
    first_name: '',
    last_name: '',
    zipcode: '',
    city: '',
    phone_number: '',
    patient: '' as any,
    caregiver: '' as any,
  }
  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private desiredDateService: DesiredDateService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.data = this.config.data

    if (this.data.isEdit) {
      this.values = this.data
      this.values.times = [
        {
          no_time: this.data.no_time,
          date_at: dayjs(this.data.date_at).format('DD.MM.YYYY'),
          time_from: dayjs(this.data.time_from).format('HH:mm'),
          time_to: dayjs(this.data.time_to).format('HH:mm'),
        },
      ]
    }

    if (this.data.fromCustomer) {
      this.values.type = 'CURRENT'
      this.values.patient = this.data.patient
    }

    this.setTabIndexForIconButtons()
  }

  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }

  public checkForTimes(
    event: any,
    index: number,
    type: 'time_from' | 'time_to'
  ): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values.times[index][type] = `0${value}:00`
    } else if (value.length === 2) {
      this.values.times[index][type] = `${value}:00`
    } else if (value.length === 4) {
      this.values.times[index][
        type
      ] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregivers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.caregivers = results
      })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.desiredDateService.update(this.data.id, this.values)
      : this.desiredDateService.store(this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DesiredDateChanged)
        this.ref.close()
        this.toastService.success(
          'Wunschtermin gespeichert',
          'Wunschtermin wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public addPosition(): void {
    this.values.times.push({
      no_time: false,
      date_at: '',
      time_from: '',
      time_to: '',
    })

    this.setTabIndexForIconButtons()
  }

  removePosition(time: any): void {
    this.values.times = this.values.times.filter((item: any) => item != time)
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Wunschtermin wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.desiredDateService.delete(this.data.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DesiredDateChanged)
            this.ref.close()
            this.toastService.success(
              'Wunschtermin gelöscht',
              'Wunschtermin wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
