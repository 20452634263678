import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { TrainingService } from '../../../services/training.service'
import { ConfirmationService } from 'primeng/api'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverDateService } from '../../../services/caregiver-date.service'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { CaregiverTimeService } from '../../../services/caregiver-time.service'
import { CaregiverTimeModel } from '../../../models/caregiver/caregiver-time.model'
import { SearchService } from '../../../services/search.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { UserCustomerService } from '../../../services/user-customer.service'

@Component({
  selector: 'app-add-user-customer-request-dialog',
  templateUrl: './add-user-customer-request-dialog.component.html',
})
export class AddUserCustomerRequestDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null

  public isEdit = false

  public freeTimeRequestToLate = false
  public minDateFreeTimeRequest: any
  public minDateVacationRequest: any

  public appointments: any[] = []
  public displayName = ''

  public dateTypes = [
    { label: 'Urlaub / Terminsperre', value: 'VACATION_REQUEST' },
    { label: 'Wunschtermin', value: 'APPOINTMENT_REQUEST' },
    { label: 'Arzttermin', value: 'DOCTOR_APPOINTMENT_REQUEST' },
    { label: 'Einkaufsfahrt', value: 'SHOPPING_APPOINTMENT_REQUEST' },
    { label: 'Beratungseinsatz', value: 'CONSULTING_REQUEST' },
  ]

  public currentTime: CaregiverTimeModel = new CaregiverTimeModel()

  public caregivers: CaregiverDetailModel[] = []
  public data: any = {}
  public values = {
    date_type: '' as any,
    with_time: false,
    from: '',
    to: '',
    from_time: '',
    to_time: '',
    comment: '',
    type: '',
  }
  public submitted = false
  public hasVacation = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private helperService: HelperService,
    private caregiverTimeService: CaregiverTimeService,
    private trainingService: TrainingService,
    private caregiverDateService: CaregiverDateService,
    private userCaregiverService: UserCaregiverService,
    private userCustomerService: UserCustomerService,
    private eventbus: EventBusService,
    public caregiverService: CaregiverService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)

    this.data = this.config.data

    this.isEdit = this.data.isEdit

    if (this.isEdit) {
      this.values.date_type = this.data.request.date_type
      this.values.from_time = this.data.request.from_time
      this.values.to_time = this.data.request.to_time
      this.values.comment = this.data.request.comment
      this.values.with_time = this.data.request.with_time
      this.values.from = this.data.request.from_formatted
      this.values.to = this.data.request.to_formatted
    }

    // Bei Urlaub darf nur das nächste Jahr ausgewählt werden.
    this.minDateVacationRequest = dayjs()
      .startOf('year')
      .add(1, 'year')
      .toDate()

    // Bei Wunschfrei darf der aktuelle Monat nicht ausgewählt werden.
    this.minDateFreeTimeRequest = dayjs()
      .startOf('month')
      .add(1, 'month')
      .toDate()

    // Sobald der 10te des aktuellen Monats vorbei ist, darf auch
    // kein Wunschfrei für den Folgemonat mehr eingetragen werden.
    if (dayjs().get('date') > 10) {
      this.freeTimeRequestToLate = true
      this.minDateFreeTimeRequest = dayjs()
        .startOf('month')
        .add(2, 'month')
        .toDate()
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  public setEndTime(): void {
    const weeks = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const day = dayjs(this.values.from, 'DD.MM.YYYY').day()

    const week = this.currentTime.days_with_key[weeks[day]]

    if (week) {
      this.values.to_time = week.has_second_block
        ? week.end_second
        : week.end_first
    }
  }

  public dayFromSelected(event: any): void {}

  public dayToSelected(event: any): void {}

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public checkForTimes(event: any, type: 'from_time' | 'to_time'): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.values[type] = `${value}:00`
    } else if (value.length === 4) {
      this.values[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  /**
   * Löscht die Datumseinträge, sobald Date-Type geändert wird.
   * Hat den Hintergrund, dass es zu Fehlern kommen kann, wenn
   * die Datumsfelder eine Uhrzeit haben (wenn z.B. von Urlaub auf Fortbildung gewechselt wird).
   */
  public dateTypeChanged(): void {
    this.values.with_time = false
    this.values.from = ''
    this.values.to = ''
  }

  /**
   * Lädt eine Datei hoch.
   */
  public upload(): void {
    this.hasVacation = false

    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    const dateFrom = dayjs(this.values.from, 'DD.MM.YYYY')
    const dateTo = dayjs(this.values.to, 'DD.MM.YYYY')

    if (dateFrom.isAfter(dateTo)) {
      alert('"Tag von" darf nicht größer als "Tag bis" sein')
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.userCustomerService.updateDateRequest(
          this.data.request.id,
          this.values
        )
      : this.userCustomerService.storeDateRequest(this.values)

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CustomerDateChanged)
        this.toastService.success(
          'Anfrage gespeichert',
          'Die Anfrage wurde erfolgreich gespeichert'
        )

        this.ref.close()
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else if (error.status === StatusCodes.CONFLICT) {
          this.hasVacation = true
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }
}
