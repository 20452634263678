<app-loader [loading]="loading"></app-loader>

<div class="content-actions" *ngIf="!loading">
  <i class="tio icon-large">user_add</i>

  <h2>
    <i class="tio">user_add</i>
    Kunde werben
  </h2>
</div>

<form #form="ngForm" class="content-offer-container">
  <div class="content-offer-forms" *ngIf="!loading">
    <ng-container>
      <div class="content new-content-container">
        <header class="content-header">
          <span>Grunddaten</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.first_patient.form_of_address"
                [options]="formOfAddress"
                inputId="first-person-form-of-address"
                #first_person_form_of_address="ngModel"
                name="first-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  first_person_form_of_address.invalid &&
                  (first_person_form_of_address.dirty ||
                    first_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_form_of_address.errors?.required">
                  <small>Bitte auswählen</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-first-name">Vorname *</label>
              <input
                id="first-person-first-name"
                name="first-person-first-name"
                [(ngModel)]="data.first_patient.first_name"
                type="text"
                pInputText
                #first_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_first_name.errors?.required">
                  <small>Bitte ausfüllen</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-last-name">Nachname *</label>
              <input
                id="first-person-last-name"
                name="first-person-last-name"
                [(ngModel)]="data.first_patient.last_name"
                type="text"
                #first_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                pInputText
              />
              <div
                *ngIf="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_last_name.errors?.required">
                  <small>Bitte ausfüllen</small>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-6">
              <label for="first-person-zipcode">PLZ</label>
              <input
                id="first-person-zipcode"
                name="first-person-zipcode"
                (change)="loadCity('first_patient')"
                [(ngModel)]="data.first_patient.zipcode"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-12 p-sm-6">
              <label
                for="first-person-city"
                style="justify-content: space-between"
                >Ort
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
                <input
                  id="first-person-city"
                  name="first-person-city"
                  [(ngModel)]="data.first_patient.city"
                  type="text"
                  pInputText
                  #first_person_city="ngModel"
                  readonly
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="content new-content-container">
        <header class="content-header">
          <span>Kontaktdaten</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-6">
              <label for="first-person-phone-1">Telefon</label>
              <input
                id="first-person-phone-1"
                name="first-person-phone-1"
                [(ngModel)]="data.first_patient.phone_1"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-12 p-sm-6">
              <label for="first-person-email">E-Mail</label>
              <input
                id="first-person-email"
                name="first-person-email"
                [(ngModel)]="data.first_patient.email"
                type="email"
                pInputText
                #first_person_email="ngModel"
                email
              />
            </div>
          </div>
        </div>
      </div>

      <div class="content new-content-container">
        <header class="content-header">
          <span>Abschluss</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="summary_from_recommendation">Bemerkung</label>
              <textarea
                id="summary_from_recommendation"
                name="summary_from_recommendation"
                [rows]="5"
                [(ngModel)]="data.summary_from_recommendation"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="content-footer" [class.sticky]="isEditMode">
      <span></span>
      <button
        pButton
        (click)="save()"
        label="Speichern"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
